const SCHOOLS_BY_NAME_EMN_RESPONSE = [
  {
    COMBOKEY: '040973401517',
    LEA_NAME: 'AZ Dept of Juvenile Corrections',
    LEA_STATE: 'AZ',
    RR_AM_F_WH_F_EMN: null,
    RR_AM_M_POP_EMN: 0,
    RR_AM_M_WH_M_EMN: 0,
    RR_AS_F_POP_EMN: null,
    RR_AS_F_WH_F_EMN: null,
    RR_AS_M_POP_EMN: 0,
    RR_AS_M_WH_M_EMN: 0,
    RR_BL_F_POP_EMN: 0,
    RR_BL_F_WH_F_EMN: null,
    RR_BL_M_POP_EMN: 1.474358974,
    RR_BL_M_WH_M_EMN: 0.730769231,
    RR_HI_F_POP_EMN: 0,
    RR_HI_F_WH_F_EMN: null,
    RR_HI_M_POP_EMN: 0.752941176,
    RR_HI_M_WH_M_EMN: 0.447058824,
    RR_HP_F_POP_EMN: null,
    RR_HP_F_WH_F_EMN: null,
    RR_HP_M_POP_EMN: null,
    RR_HP_M_WH_M_EMN: null,
    RR_TR_F_POP_EMN: null,
    RR_TR_F_WH_F_EMN: null,
    RR_TR_M_POP_EMN: 0,
    RR_TR_M_WH_M_EMN: 0,
    RR_WH_F_POP_EMN: 0,
    RR_WH_M_POP_EMN: 2.550607287,
    SCHOOL_NAME: 'Adobe Mountain School',
    __typename: 'Emotionaldisturbance',
  },
  {
    COMBOKEY: '130174003915',
    LEA_NAME: 'DeKalb County',
    LEA_STATE: 'GA',
    RR_AM_F_WH_F_EMN: null,
    RR_AM_M_POP_EMN: 0,
    RR_AM_M_WH_M_EMN: null,
    RR_AS_F_POP_EMN: 0,
    RR_AS_F_WH_F_EMN: null,
    RR_AS_M_POP_EMN: 0,
    RR_AS_M_WH_M_EMN: null,
    RR_BL_F_POP_EMN: 0,
    RR_BL_F_WH_F_EMN: null,
    RR_BL_M_POP_EMN: null,
    RR_BL_M_WH_M_EMN: null,
    RR_HI_F_POP_EMN: 0,
    RR_HI_F_WH_F_EMN: null,
    RR_HI_M_POP_EMN: 0,
    RR_HI_M_WH_M_EMN: null,
    RR_HP_F_POP_EMN: null,
    RR_HP_F_WH_F_EMN: null,
    RR_HP_M_POP_EMN: null,
    RR_HP_M_WH_M_EMN: null,
    RR_TR_F_POP_EMN: 0,
    RR_TR_F_WH_F_EMN: null,
    RR_TR_M_POP_EMN: 0,
    RR_TR_M_WH_M_EMN: null,
    RR_WH_F_POP_EMN: null,
    RR_WH_M_POP_EMN: null,
    SCHOOL_NAME: 'Arabia Mountain High School - Academy of Engineering- Medic',
    __typename: 'Emotionaldisturbance',
  },
  {
    COMBOKEY: '040042602528',
    LEA_NAME: 'Arizona Agribusiness & Equine Center Inc. 3',
    LEA_STATE: 'AZ',
    RR_AM_F_WH_F_EMN: null,
    RR_AM_M_POP_EMN: null,
    RR_AM_M_WH_M_EMN: null,
    RR_AS_F_POP_EMN: 0,
    RR_AS_F_WH_F_EMN: null,
    RR_AS_M_POP_EMN: 0,
    RR_AS_M_WH_M_EMN: null,
    RR_BL_F_POP_EMN: 0,
    RR_BL_F_WH_F_EMN: null,
    RR_BL_M_POP_EMN: 0,
    RR_BL_M_WH_M_EMN: null,
    RR_HI_F_POP_EMN: null,
    RR_HI_F_WH_F_EMN: null,
    RR_HI_M_POP_EMN: 0,
    RR_HI_M_WH_M_EMN: null,
    RR_HP_F_POP_EMN: 0,
    RR_HP_F_WH_F_EMN: null,
    RR_HP_M_POP_EMN: null,
    RR_HP_M_WH_M_EMN: null,
    RR_TR_F_POP_EMN: null,
    RR_TR_F_WH_F_EMN: null,
    RR_TR_M_POP_EMN: 0,
    RR_TR_M_WH_M_EMN: null,
    RR_WH_F_POP_EMN: 0,
    RR_WH_M_POP_EMN: 0,
    SCHOOL_NAME: 'Arizona Agribusiness & Equine Center  Inc. - Red Mountain',
    __typename: 'Emotionaldisturbance',
  },
  {
    COMBOKEY: '040023902533',
    LEA_NAME: 'P.L.C. Charter Schools',
    LEA_STATE: 'AZ',
    RR_AM_F_WH_F_EMN: 0,
    RR_AM_M_POP_EMN: 0,
    RR_AM_M_WH_M_EMN: null,
    RR_AS_F_POP_EMN: 0,
    RR_AS_F_WH_F_EMN: 0,
    RR_AS_M_POP_EMN: 0,
    RR_AS_M_WH_M_EMN: null,
    RR_BL_F_POP_EMN: 0,
    RR_BL_F_WH_F_EMN: 0,
    RR_BL_M_POP_EMN: 0,
    RR_BL_M_WH_M_EMN: null,
    RR_HI_F_POP_EMN: 1.318087318,
    RR_HI_F_WH_F_EMN: 0.081081081,
    RR_HI_M_POP_EMN: 0,
    RR_HI_M_WH_M_EMN: null,
    RR_HP_F_POP_EMN: null,
    RR_HP_F_WH_F_EMN: null,
    RR_HP_M_POP_EMN: 0,
    RR_HP_M_WH_M_EMN: null,
    RR_TR_F_POP_EMN: 0,
    RR_TR_F_WH_F_EMN: 0,
    RR_TR_M_POP_EMN: 0,
    RR_TR_M_WH_M_EMN: null,
    RR_WH_F_POP_EMN: 27.58974359,
    RR_WH_M_POP_EMN: 0,
    SCHOOL_NAME: 'Arts Academy at Estrella Mountain',
    __typename: 'Emotionaldisturbance',
  },
  {
    COMBOKEY: '530030002978',
    LEA_NAME: 'Auburn School District',
    LEA_STATE: 'WA',
    RR_AM_F_WH_F_EMN: null,
    RR_AM_M_POP_EMN: 48.8125,
    RR_AM_M_WH_M_EMN: 47.625,
    RR_AS_F_POP_EMN: 0,
    RR_AS_F_WH_F_EMN: null,
    RR_AS_M_POP_EMN: 0,
    RR_AS_M_WH_M_EMN: 0,
    RR_BL_F_POP_EMN: 0,
    RR_BL_F_WH_F_EMN: null,
    RR_BL_M_POP_EMN: 4.65625,
    RR_BL_M_WH_M_EMN: 4.7625,
    RR_HI_F_POP_EMN: 0,
    RR_HI_F_WH_F_EMN: null,
    RR_HI_M_POP_EMN: 0,
    RR_HI_M_WH_M_EMN: 0,
    RR_HP_F_POP_EMN: 0,
    RR_HP_F_WH_F_EMN: null,
    RR_HP_M_POP_EMN: 0,
    RR_HP_M_WH_M_EMN: 0,
    RR_TR_F_POP_EMN: 2.74863388,
    RR_TR_F_WH_F_EMN: null,
    RR_TR_M_POP_EMN: 10.25170068,
    RR_TR_M_WH_M_EMN: 9.071428571,
    RR_WH_F_POP_EMN: 0,
    RR_WH_M_POP_EMN: 0.780183727,
    SCHOOL_NAME: 'Auburn Mountainview High School',
    __typename: 'Emotionaldisturbance',
  },
  {
    COMBOKEY: '080354000461',
    LEA_NAME: 'Eagle County School District No. Re 50',
    LEA_STATE: 'CO',
    RR_AM_F_WH_F_EMN: null,
    RR_AM_M_POP_EMN: 0,
    RR_AM_M_WH_M_EMN: null,
    RR_AS_F_POP_EMN: 0,
    RR_AS_F_WH_F_EMN: null,
    RR_AS_M_POP_EMN: 0,
    RR_AS_M_WH_M_EMN: null,
    RR_BL_F_POP_EMN: 0,
    RR_BL_F_WH_F_EMN: null,
    RR_BL_M_POP_EMN: 0,
    RR_BL_M_WH_M_EMN: null,
    RR_HI_F_POP_EMN: null,
    RR_HI_F_WH_F_EMN: null,
    RR_HI_M_POP_EMN: 0,
    RR_HI_M_WH_M_EMN: null,
    RR_HP_F_POP_EMN: null,
    RR_HP_F_WH_F_EMN: null,
    RR_HP_M_POP_EMN: null,
    RR_HP_M_WH_M_EMN: null,
    RR_TR_F_POP_EMN: 0,
    RR_TR_F_WH_F_EMN: null,
    RR_TR_M_POP_EMN: 0,
    RR_TR_M_WH_M_EMN: null,
    RR_WH_F_POP_EMN: 0,
    RR_WH_M_POP_EMN: 0,
    SCHOOL_NAME: 'BATTLE MOUNTAIN HIGH SCHOOL',
    __typename: 'Emotionaldisturbance',
  },
  {
    COMBOKEY: '280411000775',
    LEA_NAME: 'SOUTH TIPPAH SCHOOL DIST',
    LEA_STATE: 'MS',
    RR_AM_F_WH_F_EMN: null,
    RR_AM_M_POP_EMN: null,
    RR_AM_M_WH_M_EMN: null,
    RR_AS_F_POP_EMN: null,
    RR_AS_F_WH_F_EMN: null,
    RR_AS_M_POP_EMN: null,
    RR_AS_M_WH_M_EMN: null,
    RR_BL_F_POP_EMN: 0.966911765,
    RR_BL_F_WH_F_EMN: null,
    RR_BL_M_POP_EMN: 6.380952381,
    RR_BL_M_WH_M_EMN: 4.19047619,
    RR_HI_F_POP_EMN: 0,
    RR_HI_F_WH_F_EMN: null,
    RR_HI_M_POP_EMN: 0,
    RR_HI_M_WH_M_EMN: 0,
    RR_HP_F_POP_EMN: null,
    RR_HP_F_WH_F_EMN: null,
    RR_HP_M_POP_EMN: null,
    RR_HP_M_WH_M_EMN: null,
    RR_TR_F_POP_EMN: null,
    RR_TR_F_WH_F_EMN: null,
    RR_TR_M_POP_EMN: null,
    RR_TR_M_WH_M_EMN: null,
    RR_WH_F_POP_EMN: 0,
    RR_WH_M_POP_EMN: 0.690340909,
    SCHOOL_NAME: 'BLUE MOUNTAIN HIGH SCHOOL',
    __typename: 'Emotionaldisturbance',
  },
  {
    COMBOKEY: '361419001161',
    LEA_NAME: 'HENDRICK HUDSON CENTRAL SCHOOL DISTRICT',
    LEA_STATE: 'NY',
    RR_AM_F_WH_F_EMN: null,
    RR_AM_M_POP_EMN: null,
    RR_AM_M_WH_M_EMN: null,
    RR_AS_F_POP_EMN: 0,
    RR_AS_F_WH_F_EMN: null,
    RR_AS_M_POP_EMN: 0,
    RR_AS_M_WH_M_EMN: null,
    RR_BL_F_POP_EMN: 0,
    RR_BL_F_WH_F_EMN: null,
    RR_BL_M_POP_EMN: 0,
    RR_BL_M_WH_M_EMN: null,
    RR_HI_F_POP_EMN: 0,
    RR_HI_F_WH_F_EMN: null,
    RR_HI_M_POP_EMN: 7.328358209,
    RR_HI_M_WH_M_EMN: null,
    RR_HP_F_POP_EMN: null,
    RR_HP_F_WH_F_EMN: null,
    RR_HP_M_POP_EMN: null,
    RR_HP_M_WH_M_EMN: null,
    RR_TR_F_POP_EMN: 49.72727273,
    RR_TR_F_WH_F_EMN: null,
    RR_TR_M_POP_EMN: 0,
    RR_TR_M_WH_M_EMN: null,
    RR_WH_F_POP_EMN: 0,
    RR_WH_M_POP_EMN: 0,
    SCHOOL_NAME: 'BLUE MOUNTAIN MIDDLE SCHOOL',
    __typename: 'Emotionaldisturbance',
  },
  {
    COMBOKEY: '530732001090',
    LEA_NAME: 'Richland School District',
    LEA_STATE: 'WA',
    RR_AM_F_WH_F_EMN: null,
    RR_AM_M_POP_EMN: null,
    RR_AM_M_WH_M_EMN: null,
    RR_AS_F_POP_EMN: 0,
    RR_AS_F_WH_F_EMN: null,
    RR_AS_M_POP_EMN: 0,
    RR_AS_M_WH_M_EMN: null,
    RR_BL_F_POP_EMN: 0,
    RR_BL_F_WH_F_EMN: null,
    RR_BL_M_POP_EMN: 0,
    RR_BL_M_WH_M_EMN: null,
    RR_HI_F_POP_EMN: 0,
    RR_HI_F_WH_F_EMN: null,
    RR_HI_M_POP_EMN: 0,
    RR_HI_M_WH_M_EMN: null,
    RR_HP_F_POP_EMN: null,
    RR_HP_F_WH_F_EMN: null,
    RR_HP_M_POP_EMN: null,
    RR_HP_M_WH_M_EMN: null,
    RR_TR_F_POP_EMN: null,
    RR_TR_F_WH_F_EMN: null,
    RR_TR_M_POP_EMN: 0,
    RR_TR_M_WH_M_EMN: null,
    RR_WH_F_POP_EMN: 0,
    RR_WH_M_POP_EMN: 0,
    SCHOOL_NAME: 'Badger Mountain Elementary',
    __typename: 'Emotionaldisturbance',
  },
  {
    COMBOKEY: '060327000413',
    LEA_NAME: 'Arvin Union',
    LEA_STATE: 'CA',
    RR_AM_F_WH_F_EMN: null,
    RR_AM_M_POP_EMN: null,
    RR_AM_M_WH_M_EMN: null,
    RR_AS_F_POP_EMN: null,
    RR_AS_F_WH_F_EMN: null,
    RR_AS_M_POP_EMN: null,
    RR_AS_M_WH_M_EMN: null,
    RR_BL_F_POP_EMN: 0,
    RR_BL_F_WH_F_EMN: null,
    RR_BL_M_POP_EMN: 0,
    RR_BL_M_WH_M_EMN: null,
    RR_HI_F_POP_EMN: 0,
    RR_HI_F_WH_F_EMN: null,
    RR_HI_M_POP_EMN: null,
    RR_HI_M_WH_M_EMN: null,
    RR_HP_F_POP_EMN: null,
    RR_HP_F_WH_F_EMN: null,
    RR_HP_M_POP_EMN: null,
    RR_HP_M_WH_M_EMN: null,
    RR_TR_F_POP_EMN: null,
    RR_TR_F_WH_F_EMN: null,
    RR_TR_M_POP_EMN: null,
    RR_TR_M_WH_M_EMN: null,
    RR_WH_F_POP_EMN: 0,
    RR_WH_M_POP_EMN: 0,
    SCHOOL_NAME: 'Bear Mountain Elementary',
    __typename: 'Emotionaldisturbance',
  },
];

export default SCHOOLS_BY_NAME_EMN_RESPONSE;
