const SCHOOLS_BY_NAME_TBI_RESPONSE = [
  {
    COMBOKEY: '350006000893',
    LEA_NAME: 'ALBUQUERQUE PUBLIC SCHOOLS',
    LEA_STATE: 'NM',
    RR_AM_F_WH_F_TBI: null,
    RR_AM_M_POP_TBI: null,
    RR_AM_M_WH_M_TBI: null,
    RR_AS_F_POP_TBI: null,
    RR_AS_F_WH_F_TBI: null,
    RR_AS_M_POP_TBI: null,
    RR_AS_M_WH_M_TBI: null,
    RR_BL_F_POP_TBI: null,
    RR_BL_F_WH_F_TBI: null,
    RR_BL_M_POP_TBI: null,
    RR_BL_M_WH_M_TBI: null,
    RR_HI_F_POP_TBI: null,
    RR_HI_F_WH_F_TBI: null,
    RR_HI_M_POP_TBI: null,
    RR_HI_M_WH_M_TBI: null,
    RR_HP_F_POP_TBI: null,
    RR_HP_F_WH_F_TBI: null,
    RR_HP_M_POP_TBI: null,
    RR_HP_M_WH_M_TBI: null,
    RR_TR_F_POP_TBI: null,
    RR_TR_F_WH_F_TBI: null,
    RR_TR_M_POP_TBI: null,
    RR_TR_M_WH_M_TBI: null,
    RR_WH_F_POP_TBI: null,
    RR_WH_M_POP_TBI: null,
    SCHOOL_NAME: 'ABQ CHARTER ACADEMY',
    __typename: 'Traumaticbraininjury',
  },
  {
    COMBOKEY: '080002000727',
    LEA_NAME: 'State Charter School Institute',
    LEA_STATE: 'CO',
    RR_AM_F_WH_F_TBI: 0,
    RR_AM_M_POP_TBI: 0,
    RR_AM_M_WH_M_TBI: null,
    RR_AS_F_POP_TBI: 0,
    RR_AS_F_WH_F_TBI: 0,
    RR_AS_M_POP_TBI: 0,
    RR_AS_M_WH_M_TBI: null,
    RR_BL_F_POP_TBI: 0,
    RR_BL_F_WH_F_TBI: 0,
    RR_BL_M_POP_TBI: 0,
    RR_BL_M_WH_M_TBI: null,
    RR_HI_F_POP_TBI: 0,
    RR_HI_F_WH_F_TBI: 0,
    RR_HI_M_POP_TBI: 0,
    RR_HI_M_WH_M_TBI: null,
    RR_HP_F_POP_TBI: null,
    RR_HP_F_WH_F_TBI: null,
    RR_HP_M_POP_TBI: 0,
    RR_HP_M_WH_M_TBI: null,
    RR_TR_F_POP_TBI: 0,
    RR_TR_F_WH_F_TBI: 0,
    RR_TR_M_POP_TBI: 0,
    RR_TR_M_WH_M_TBI: null,
    RR_WH_F_POP_TBI: null,
    RR_WH_M_POP_TBI: 0,
    SCHOOL_NAME: 'ACADEMY OF CHARTER SCHOOLS',
    __typename: 'Traumaticbraininjury',
  },
  {
    COMBOKEY: '061182013023',
    LEA_NAME: 'East Side Union High',
    LEA_STATE: 'CA',
    RR_AM_F_WH_F_TBI: null,
    RR_AM_M_POP_TBI: null,
    RR_AM_M_WH_M_TBI: null,
    RR_AS_F_POP_TBI: null,
    RR_AS_F_WH_F_TBI: null,
    RR_AS_M_POP_TBI: null,
    RR_AS_M_WH_M_TBI: null,
    RR_BL_F_POP_TBI: null,
    RR_BL_F_WH_F_TBI: null,
    RR_BL_M_POP_TBI: null,
    RR_BL_M_WH_M_TBI: null,
    RR_HI_F_POP_TBI: null,
    RR_HI_F_WH_F_TBI: null,
    RR_HI_M_POP_TBI: null,
    RR_HI_M_WH_M_TBI: null,
    RR_HP_F_POP_TBI: null,
    RR_HP_F_WH_F_TBI: null,
    RR_HP_M_POP_TBI: null,
    RR_HP_M_WH_M_TBI: null,
    RR_TR_F_POP_TBI: null,
    RR_TR_F_WH_F_TBI: null,
    RR_TR_M_POP_TBI: null,
    RR_TR_M_WH_M_TBI: null,
    RR_WH_F_POP_TBI: null,
    RR_WH_M_POP_TBI: null,
    SCHOOL_NAME: 'ACE Charter High',
    __typename: 'Traumaticbraininjury',
  },
  {
    COMBOKEY: '350013100810',
    LEA_NAME: 'AMY BIEHL CHARTER HIGH SCHOOL',
    LEA_STATE: 'NM',
    RR_AM_F_WH_F_TBI: 0,
    RR_AM_M_POP_TBI: 0,
    RR_AM_M_WH_M_TBI: null,
    RR_AS_F_POP_TBI: 0,
    RR_AS_F_WH_F_TBI: 0,
    RR_AS_M_POP_TBI: null,
    RR_AS_M_WH_M_TBI: null,
    RR_BL_F_POP_TBI: 0,
    RR_BL_F_WH_F_TBI: 0,
    RR_BL_M_POP_TBI: 0,
    RR_BL_M_WH_M_TBI: null,
    RR_HI_F_POP_TBI: 0,
    RR_HI_F_WH_F_TBI: 0,
    RR_HI_M_POP_TBI: 0,
    RR_HI_M_WH_M_TBI: null,
    RR_HP_F_POP_TBI: 0,
    RR_HP_F_WH_F_TBI: 0,
    RR_HP_M_POP_TBI: null,
    RR_HP_M_WH_M_TBI: null,
    RR_TR_F_POP_TBI: 0,
    RR_TR_F_WH_F_TBI: 0,
    RR_TR_M_POP_TBI: null,
    RR_TR_M_WH_M_TBI: null,
    RR_WH_F_POP_TBI: null,
    RR_WH_M_POP_TBI: 0,
    SCHOOL_NAME: 'AMY BIEHL CHARTER HIGH SCHOOL',
    __typename: 'Traumaticbraininjury',
  },
  {
    COMBOKEY: '350016101048',
    LEA_NAME: 'ANTHONY CHARTER SCHOOL',
    LEA_STATE: 'NM',
    RR_AM_F_WH_F_TBI: null,
    RR_AM_M_POP_TBI: null,
    RR_AM_M_WH_M_TBI: null,
    RR_AS_F_POP_TBI: null,
    RR_AS_F_WH_F_TBI: null,
    RR_AS_M_POP_TBI: null,
    RR_AS_M_WH_M_TBI: null,
    RR_BL_F_POP_TBI: null,
    RR_BL_F_WH_F_TBI: null,
    RR_BL_M_POP_TBI: null,
    RR_BL_M_WH_M_TBI: null,
    RR_HI_F_POP_TBI: null,
    RR_HI_F_WH_F_TBI: null,
    RR_HI_M_POP_TBI: null,
    RR_HI_M_WH_M_TBI: null,
    RR_HP_F_POP_TBI: null,
    RR_HP_F_WH_F_TBI: null,
    RR_HP_M_POP_TBI: null,
    RR_HP_M_WH_M_TBI: null,
    RR_TR_F_POP_TBI: null,
    RR_TR_F_WH_F_TBI: null,
    RR_TR_M_POP_TBI: null,
    RR_TR_M_WH_M_TBI: null,
    RR_WH_F_POP_TBI: null,
    RR_WH_M_POP_TBI: null,
    SCHOOL_NAME: 'ANTHONY CHARTER SCHOOL',
    __typename: 'Traumaticbraininjury',
  },
  {
    COMBOKEY: '250005101247',
    LEA_NAME: 'Abby Kelley Foster Charter Public (District)',
    LEA_STATE: 'MA',
    RR_AM_F_WH_F_TBI: 0,
    RR_AM_M_POP_TBI: null,
    RR_AM_M_WH_M_TBI: null,
    RR_AS_F_POP_TBI: 0,
    RR_AS_F_WH_F_TBI: 0,
    RR_AS_M_POP_TBI: 0,
    RR_AS_M_WH_M_TBI: 0,
    RR_BL_F_POP_TBI: 1.216026242,
    RR_BL_F_WH_F_TBI: 1.675257732,
    RR_BL_M_POP_TBI: 1.048449612,
    RR_BL_M_WH_M_TBI: 1.744186047,
    RR_HI_F_POP_TBI: 2.103109656,
    RR_HI_F_WH_F_TBI: 2.765957447,
    RR_HI_M_POP_TBI: 0.970335675,
    RR_HI_M_WH_M_TBI: 1.639344262,
    RR_HP_F_POP_TBI: 0,
    RR_HP_F_WH_F_TBI: 0,
    RR_HP_M_POP_TBI: 0,
    RR_HP_M_WH_M_TBI: 0,
    RR_TR_F_POP_TBI: 0,
    RR_TR_F_WH_F_TBI: 0,
    RR_TR_M_POP_TBI: 0,
    RR_TR_M_WH_M_TBI: 0,
    RR_WH_F_POP_TBI: 0.664615385,
    RR_WH_M_POP_TBI: 0.567111111,
    SCHOOL_NAME: 'Abby Kelley Foster Charter Public School',
    __typename: 'Traumaticbraininjury',
  },
  {
    COMBOKEY: '250002100511',
    LEA_NAME: 'Academy Of the Pacific Rim Charter Public (District)',
    LEA_STATE: 'MA',
    RR_AM_F_WH_F_TBI: null,
    RR_AM_M_POP_TBI: null,
    RR_AM_M_WH_M_TBI: null,
    RR_AS_F_POP_TBI: 0,
    RR_AS_F_WH_F_TBI: null,
    RR_AS_M_POP_TBI: null,
    RR_AS_M_WH_M_TBI: null,
    RR_BL_F_POP_TBI: 0.243137255,
    RR_BL_F_WH_F_TBI: null,
    RR_BL_M_POP_TBI: 10.17391304,
    RR_BL_M_WH_M_TBI: null,
    RR_HI_F_POP_TBI: 0.55625,
    RR_HI_F_WH_F_TBI: null,
    RR_HI_M_POP_TBI: 0,
    RR_HI_M_WH_M_TBI: null,
    RR_HP_F_POP_TBI: null,
    RR_HP_F_WH_F_TBI: null,
    RR_HP_M_POP_TBI: 0,
    RR_HP_M_WH_M_TBI: null,
    RR_TR_F_POP_TBI: 0,
    RR_TR_F_WH_F_TBI: null,
    RR_TR_M_POP_TBI: 0,
    RR_TR_M_WH_M_TBI: null,
    RR_WH_F_POP_TBI: 0,
    RR_WH_M_POP_TBI: 0,
    SCHOOL_NAME: 'Academy Of the Pacific Rim Charter Public School',
    __typename: 'Traumaticbraininjury',
  },
  {
    COMBOKEY: '250008501984',
    LEA_NAME: 'Advanced Math and Science Academy Charter (District)',
    LEA_STATE: 'MA',
    RR_AM_F_WH_F_TBI: null,
    RR_AM_M_POP_TBI: 0,
    RR_AM_M_WH_M_TBI: 0,
    RR_AS_F_POP_TBI: 0,
    RR_AS_F_WH_F_TBI: 0,
    RR_AS_M_POP_TBI: 0,
    RR_AS_M_WH_M_TBI: 0,
    RR_BL_F_POP_TBI: 0,
    RR_BL_F_WH_F_TBI: 0,
    RR_BL_M_POP_TBI: 0,
    RR_BL_M_WH_M_TBI: 0,
    RR_HI_F_POP_TBI: 0,
    RR_HI_F_WH_F_TBI: 0,
    RR_HI_M_POP_TBI: 0,
    RR_HI_M_WH_M_TBI: 0,
    RR_HP_F_POP_TBI: 0,
    RR_HP_F_WH_F_TBI: 0,
    RR_HP_M_POP_TBI: 0,
    RR_HP_M_WH_M_TBI: 0,
    RR_TR_F_POP_TBI: 0,
    RR_TR_F_WH_F_TBI: 0,
    RR_TR_M_POP_TBI: 0,
    RR_TR_M_WH_M_TBI: 0,
    RR_WH_F_POP_TBI: 1.052050473,
    RR_WH_M_POP_TBI: 3.622857143,
    SCHOOL_NAME: 'Advanced Math and Science Academy Charter School',
    __typename: 'Traumaticbraininjury',
  },
  {
    COMBOKEY: '250052502708',
    LEA_NAME: 'Alma del Mar Charter School (District)',
    LEA_STATE: 'MA',
    RR_AM_F_WH_F_TBI: null,
    RR_AM_M_POP_TBI: 0,
    RR_AM_M_WH_M_TBI: null,
    RR_AS_F_POP_TBI: 0,
    RR_AS_F_WH_F_TBI: null,
    RR_AS_M_POP_TBI: 0,
    RR_AS_M_WH_M_TBI: null,
    RR_BL_F_POP_TBI: 2.127777778,
    RR_BL_F_WH_F_TBI: null,
    RR_BL_M_POP_TBI: 2.962121212,
    RR_BL_M_WH_M_TBI: null,
    RR_HI_F_POP_TBI: 1.728,
    RR_HI_F_WH_F_TBI: null,
    RR_HI_M_POP_TBI: 1.188461538,
    RR_HI_M_WH_M_TBI: null,
    RR_HP_F_POP_TBI: 0,
    RR_HP_F_WH_F_TBI: null,
    RR_HP_M_POP_TBI: 0,
    RR_HP_M_WH_M_TBI: null,
    RR_TR_F_POP_TBI: null,
    RR_TR_F_WH_F_TBI: null,
    RR_TR_M_POP_TBI: null,
    RR_TR_M_WH_M_TBI: null,
    RR_WH_F_POP_TBI: 0,
    RR_WH_M_POP_TBI: 0,
    SCHOOL_NAME: 'Alma del Mar Charter School',
    __typename: 'Traumaticbraininjury',
  },
  {
    COMBOKEY: '250055202827',
    LEA_NAME: 'Argosy Collegiate Charter School (District)',
    LEA_STATE: 'MA',
    RR_AM_F_WH_F_TBI: null,
    RR_AM_M_POP_TBI: 0,
    RR_AM_M_WH_M_TBI: null,
    RR_AS_F_POP_TBI: 0,
    RR_AS_F_WH_F_TBI: null,
    RR_AS_M_POP_TBI: 0,
    RR_AS_M_WH_M_TBI: null,
    RR_BL_F_POP_TBI: 0,
    RR_BL_F_WH_F_TBI: null,
    RR_BL_M_POP_TBI: 0,
    RR_BL_M_WH_M_TBI: null,
    RR_HI_F_POP_TBI: null,
    RR_HI_F_WH_F_TBI: null,
    RR_HI_M_POP_TBI: 0,
    RR_HI_M_WH_M_TBI: null,
    RR_HP_F_POP_TBI: 0,
    RR_HP_F_WH_F_TBI: null,
    RR_HP_M_POP_TBI: null,
    RR_HP_M_WH_M_TBI: null,
    RR_TR_F_POP_TBI: 0,
    RR_TR_F_WH_F_TBI: null,
    RR_TR_M_POP_TBI: 0,
    RR_TR_M_WH_M_TBI: null,
    RR_WH_F_POP_TBI: 0,
    RR_WH_M_POP_TBI: 0,
    SCHOOL_NAME: 'Argosy Collegiate Charter School',
    __typename: 'Traumaticbraininjury',
  },
];

export default SCHOOLS_BY_NAME_TBI_RESPONSE;
