const SCHOOLS_BY_NAME_OI_RESPONSE = [
  {
    COMBOKEY: '360010202466',
    LEA_NAME: 'NEW YORK CITY GEOGRAPHIC DISTRICT #30',
    LEA_STATE: 'NY',
    RR_AM_F_WH_F_OI: null,
    RR_AM_M_POP_OI: null,
    RR_AM_M_WH_M_OI: null,
    RR_AS_F_POP_OI: null,
    RR_AS_F_WH_F_OI: null,
    RR_AS_M_POP_OI: null,
    RR_AS_M_WH_M_OI: null,
    RR_BL_F_POP_OI: null,
    RR_BL_F_WH_F_OI: null,
    RR_BL_M_POP_OI: null,
    RR_BL_M_WH_M_OI: null,
    RR_HI_F_POP_OI: null,
    RR_HI_F_WH_F_OI: null,
    RR_HI_M_POP_OI: null,
    RR_HI_M_WH_M_OI: null,
    RR_HP_F_POP_OI: null,
    RR_HP_F_WH_F_OI: null,
    RR_HP_M_POP_OI: null,
    RR_HP_M_WH_M_OI: null,
    RR_TR_F_POP_OI: null,
    RR_TR_F_WH_F_OI: null,
    RR_TR_M_POP_OI: null,
    RR_TR_M_WH_M_OI: null,
    RR_WH_F_POP_OI: null,
    RR_WH_M_POP_OI: null,
    SCHOOL_NAME: 'ALBERT SHANKER SCHOOL FOR VISUAL AND PERFORMING ARTS',
    __typename: 'Orthopedicimpairment',
  },
  {
    COMBOKEY: '120174001905',
    LEA_NAME: 'ST. JOHNS',
    LEA_STATE: 'FL',
    RR_AM_F_WH_F_OI: null,
    RR_AM_M_POP_OI: 0,
    RR_AM_M_WH_M_OI: 0,
    RR_AS_F_POP_OI: 0,
    RR_AS_F_WH_F_OI: null,
    RR_AS_M_POP_OI: 0,
    RR_AS_M_WH_M_OI: 0,
    RR_BL_F_POP_OI: 3.957894737,
    RR_BL_F_WH_F_OI: null,
    RR_BL_M_POP_OI: 0,
    RR_BL_M_WH_M_OI: 0,
    RR_HI_F_POP_OI: 9.829268293,
    RR_HI_F_WH_F_OI: null,
    RR_HI_M_POP_OI: 0,
    RR_HI_M_WH_M_OI: 0,
    RR_HP_F_POP_OI: null,
    RR_HP_F_WH_F_OI: null,
    RR_HP_M_POP_OI: null,
    RR_HP_M_WH_M_OI: null,
    RR_TR_F_POP_OI: 0,
    RR_TR_F_WH_F_OI: null,
    RR_TR_M_POP_OI: 0,
    RR_TR_M_WH_M_OI: 0,
    RR_WH_F_POP_OI: 0,
    RR_WH_M_POP_OI: 1.074349442,
    SCHOOL_NAME: 'CROOKSHANK ELEMENTARY SCHOOL',
    __typename: 'Orthopedicimpairment',
  },
  {
    COMBOKEY: '62460003307',
    LEA_NAME: 'Merced City Elementary',
    LEA_STATE: 'CA',
    RR_AM_F_WH_F_OI: null,
    RR_AM_M_POP_OI: 0,
    RR_AM_M_WH_M_OI: null,
    RR_AS_F_POP_OI: 0,
    RR_AS_F_WH_F_OI: null,
    RR_AS_M_POP_OI: 0,
    RR_AS_M_WH_M_OI: null,
    RR_BL_F_POP_OI: 0,
    RR_BL_F_WH_F_OI: null,
    RR_BL_M_POP_OI: 0,
    RR_BL_M_WH_M_OI: null,
    RR_HI_F_POP_OI: 0,
    RR_HI_F_WH_F_OI: null,
    RR_HI_M_POP_OI: null,
    RR_HI_M_WH_M_OI: null,
    RR_HP_F_POP_OI: null,
    RR_HP_F_WH_F_OI: null,
    RR_HP_M_POP_OI: null,
    RR_HP_M_WH_M_OI: null,
    RR_TR_F_POP_OI: 0,
    RR_TR_F_WH_F_OI: null,
    RR_TR_M_POP_OI: 0,
    RR_TR_M_WH_M_OI: null,
    RR_WH_F_POP_OI: 0,
    RR_WH_M_POP_OI: 0,
    SCHOOL_NAME: 'Herbert H. Cruickshank Middle',
    __typename: 'Orthopedicimpairment',
  },
  {
    COMBOKEY: '484668005323',
    LEA_NAME: 'YSLETA ISD',
    LEA_STATE: 'TX',
    RR_AM_F_WH_F_OI: null,
    RR_AM_M_POP_OI: 0,
    RR_AM_M_WH_M_OI: null,
    RR_AS_F_POP_OI: 0,
    RR_AS_F_WH_F_OI: null,
    RR_AS_M_POP_OI: 0,
    RR_AS_M_WH_M_OI: null,
    RR_BL_F_POP_OI: 0,
    RR_BL_F_WH_F_OI: null,
    RR_BL_M_POP_OI: 0,
    RR_BL_M_WH_M_OI: null,
    RR_HI_F_POP_OI: 0,
    RR_HI_F_WH_F_OI: null,
    RR_HI_M_POP_OI: null,
    RR_HI_M_WH_M_OI: null,
    RR_HP_F_POP_OI: null,
    RR_HP_F_WH_F_OI: null,
    RR_HP_M_POP_OI: null,
    RR_HP_M_WH_M_OI: null,
    RR_TR_F_POP_OI: 0,
    RR_TR_F_WH_F_OI: null,
    RR_TR_M_POP_OI: 0,
    RR_TR_M_WH_M_OI: null,
    RR_WH_F_POP_OI: 0,
    RR_WH_M_POP_OI: 0,
    SCHOOL_NAME: 'J M HANKS H S',
    __typename: 'Orthopedicimpairment',
  },
  {
    COMBOKEY: '10237000961',
    LEA_NAME: 'Mobile County',
    LEA_STATE: 'AL',
    RR_AM_F_WH_F_OI: null,
    RR_AM_M_POP_OI: 0,
    RR_AM_M_WH_M_OI: 0,
    RR_AS_F_POP_OI: 0,
    RR_AS_F_WH_F_OI: null,
    RR_AS_M_POP_OI: 0,
    RR_AS_M_WH_M_OI: 0,
    RR_BL_F_POP_OI: 0,
    RR_BL_F_WH_F_OI: null,
    RR_BL_M_POP_OI: 0,
    RR_BL_M_WH_M_OI: 0,
    RR_HI_F_POP_OI: 0,
    RR_HI_F_WH_F_OI: null,
    RR_HI_M_POP_OI: 0,
    RR_HI_M_WH_M_OI: 0,
    RR_HP_F_POP_OI: 0,
    RR_HP_F_WH_F_OI: null,
    RR_HP_M_POP_OI: 0,
    RR_HP_M_WH_M_OI: 0,
    RR_TR_F_POP_OI: 0,
    RR_TR_F_WH_F_OI: null,
    RR_TR_M_POP_OI: 0,
    RR_TR_M_WH_M_OI: 0,
    RR_WH_F_POP_OI: 0,
    RR_WH_M_POP_OI: null,
    SCHOOL_NAME: 'Katherine H Hankins Middle School',
    __typename: 'Orthopedicimpairment',
  },
];

export default SCHOOLS_BY_NAME_OI_RESPONSE;
