const SCHOOLS_BY_NAME_MR_RESPONSE = [
  {
    COMBOKEY: '480783021293',
    LEA_NAME: 'ALIEF ISD',
    LEA_STATE: 'TX',
    RR_AM_F_WH_F_MR: null,
    RR_AM_M_POP_MR: 94.66666667,
    RR_AM_M_WH_M_MR: null,
    RR_AS_F_POP_MR: 0,
    RR_AS_F_WH_F_MR: null,
    RR_AS_M_POP_MR: 0,
    RR_AS_M_WH_M_MR: null,
    RR_BL_F_POP_MR: 0.698130841,
    RR_BL_F_WH_F_MR: null,
    RR_BL_M_POP_MR: 1.399810066,
    RR_BL_M_WH_M_MR: null,
    RR_HI_F_POP_MR: 1.51540154,
    RR_HI_F_WH_F_MR: null,
    RR_HI_M_POP_MR: 0.201766784,
    RR_HI_M_WH_M_MR: null,
    RR_HP_F_POP_MR: null,
    RR_HP_F_WH_F_MR: null,
    RR_HP_M_POP_MR: null,
    RR_HP_M_WH_M_MR: null,
    RR_TR_F_POP_MR: 0,
    RR_TR_F_WH_F_MR: null,
    RR_TR_M_POP_MR: 0,
    RR_TR_M_WH_M_MR: null,
    RR_WH_F_POP_MR: 0,
    RR_WH_M_POP_MR: 0,
    SCHOOL_NAME: 'BEST EL',
    __typename: 'Intellectualdisability',
  },
  {
    COMBOKEY: '481168003325',
    LEA_NAME: 'BROWNSVILLE ISD',
    LEA_STATE: 'TX',
    RR_AM_F_WH_F_MR: null,
    RR_AM_M_POP_MR: null,
    RR_AM_M_WH_M_MR: null,
    RR_AS_F_POP_MR: null,
    RR_AS_F_WH_F_MR: null,
    RR_AS_M_POP_MR: null,
    RR_AS_M_WH_M_MR: null,
    RR_BL_F_POP_MR: null,
    RR_BL_F_WH_F_MR: null,
    RR_BL_M_POP_MR: null,
    RR_BL_M_WH_M_MR: null,
    RR_HI_F_POP_MR: 0.941326531,
    RR_HI_F_WH_F_MR: null,
    RR_HI_M_POP_MR: 1.062330623,
    RR_HI_M_WH_M_MR: null,
    RR_HP_F_POP_MR: null,
    RR_HP_F_WH_F_MR: null,
    RR_HP_M_POP_MR: null,
    RR_HP_M_WH_M_MR: null,
    RR_TR_F_POP_MR: null,
    RR_TR_F_WH_F_MR: null,
    RR_TR_M_POP_MR: null,
    RR_TR_M_WH_M_MR: null,
    RR_WH_F_POP_MR: null,
    RR_WH_M_POP_MR: null,
    SCHOOL_NAME: 'BESTEIRO MIDDLE',
    __typename: 'Intellectualdisability',
  },
  {
    COMBOKEY: '270030904261',
    LEA_NAME: 'BEST ACADEMY',
    LEA_STATE: 'MN',
    RR_AM_F_WH_F_MR: null,
    RR_AM_M_POP_MR: null,
    RR_AM_M_WH_M_MR: null,
    RR_AS_F_POP_MR: null,
    RR_AS_F_WH_F_MR: null,
    RR_AS_M_POP_MR: 0,
    RR_AS_M_WH_M_MR: null,
    RR_BL_F_POP_MR: 0.656,
    RR_BL_F_WH_F_MR: null,
    RR_BL_M_POP_MR: 1.590062112,
    RR_BL_M_WH_M_MR: null,
    RR_HI_F_POP_MR: 0,
    RR_HI_F_WH_F_MR: null,
    RR_HI_M_POP_MR: null,
    RR_HI_M_WH_M_MR: null,
    RR_HP_F_POP_MR: null,
    RR_HP_F_WH_F_MR: null,
    RR_HP_M_POP_MR: null,
    RR_HP_M_WH_M_MR: null,
    RR_TR_F_POP_MR: null,
    RR_TR_F_WH_F_MR: null,
    RR_TR_M_POP_MR: null,
    RR_TR_M_WH_M_MR: null,
    RR_WH_F_POP_MR: null,
    RR_WH_M_POP_MR: 0,
    SCHOOL_NAME: 'Best Academy East at Olson',
    __typename: 'Intellectualdisability',
  },
  {
    COMBOKEY: '240066001253',
    LEA_NAME: 'Washingtion County Public Schools',
    LEA_STATE: 'MD',
    RR_AM_F_WH_F_MR: null,
    RR_AM_M_POP_MR: 0,
    RR_AM_M_WH_M_MR: 0,
    RR_AS_F_POP_MR: 0,
    RR_AS_F_WH_F_MR: null,
    RR_AS_M_POP_MR: null,
    RR_AS_M_WH_M_MR: null,
    RR_BL_F_POP_MR: 0,
    RR_BL_F_WH_F_MR: null,
    RR_BL_M_POP_MR: 1.117021277,
    RR_BL_M_WH_M_MR: 1.329787234,
    RR_HI_F_POP_MR: 8.602941176,
    RR_HI_F_WH_F_MR: null,
    RR_HI_M_POP_MR: 3.441176471,
    RR_HI_M_WH_M_MR: 3.676470588,
    RR_HP_F_POP_MR: null,
    RR_HP_F_WH_F_MR: null,
    RR_HP_M_POP_MR: null,
    RR_HP_M_WH_M_MR: null,
    RR_TR_F_POP_MR: 2.276,
    RR_TR_F_WH_F_MR: null,
    RR_TR_M_POP_MR: 0,
    RR_TR_M_WH_M_MR: 0,
    RR_WH_F_POP_MR: 0,
    RR_WH_M_POP_MR: 0.7904,
    SCHOOL_NAME: 'Bester Elementary',
    __typename: 'Intellectualdisability',
  },
  {
    COMBOKEY: '320003000004',
    LEA_NAME: 'CHURCHILL COUNTY SCHOOL DISTRICT',
    LEA_STATE: 'NV',
    RR_AM_F_WH_F_MR: null,
    RR_AM_M_POP_MR: 0,
    RR_AM_M_WH_M_MR: 0,
    RR_AS_F_POP_MR: 0,
    RR_AS_F_WH_F_MR: null,
    RR_AS_M_POP_MR: 60.25,
    RR_AS_M_WH_M_MR: 19,
    RR_BL_F_POP_MR: 0,
    RR_BL_F_WH_F_MR: null,
    RR_BL_M_POP_MR: 0,
    RR_BL_M_WH_M_MR: 0,
    RR_HI_F_POP_MR: 0,
    RR_HI_F_WH_F_MR: null,
    RR_HI_M_POP_MR: 0,
    RR_HI_M_WH_M_MR: 0,
    RR_HP_F_POP_MR: 0,
    RR_HP_F_WH_F_MR: null,
    RR_HP_M_POP_MR: null,
    RR_HP_M_WH_M_MR: null,
    RR_TR_F_POP_MR: 0,
    RR_TR_F_WH_F_MR: null,
    RR_TR_M_POP_MR: 0,
    RR_TR_M_WH_M_MR: 0,
    RR_WH_F_POP_MR: 0,
    RR_WH_M_POP_MR: 2.223684211,
    SCHOOL_NAME: 'E C  Best Elementary School',
    __typename: 'Intellectualdisability',
  },
  {
    COMBOKEY: '370153000653',
    LEA_NAME: 'Franklin County Schools',
    LEA_STATE: 'NC',
    RR_AM_F_WH_F_MR: 0,
    RR_AM_M_POP_MR: null,
    RR_AM_M_WH_M_MR: null,
    RR_AS_F_POP_MR: 0,
    RR_AS_F_WH_F_MR: 0,
    RR_AS_M_POP_MR: 0,
    RR_AS_M_WH_M_MR: 0,
    RR_BL_F_POP_MR: 0,
    RR_BL_F_WH_F_MR: 0,
    RR_BL_M_POP_MR: 0,
    RR_BL_M_WH_M_MR: 0,
    RR_HI_F_POP_MR: 3.308823529,
    RR_HI_F_WH_F_MR: 1.617647059,
    RR_HI_M_POP_MR: 0,
    RR_HI_M_WH_M_MR: 0,
    RR_HP_F_POP_MR: null,
    RR_HP_F_WH_F_MR: null,
    RR_HP_M_POP_MR: null,
    RR_HP_M_WH_M_MR: null,
    RR_TR_F_POP_MR: 0,
    RR_TR_F_WH_F_MR: 0,
    RR_TR_M_POP_MR: 0,
    RR_TR_M_WH_M_MR: 0,
    RR_WH_F_POP_MR: 1.854545455,
    RR_WH_M_POP_MR: 1.898148148,
    SCHOOL_NAME: 'Edward Best Elementary',
    __typename: 'Intellectualdisability',
  },
  {
    COMBOKEY: '261161004616',
    LEA_NAME: 'Dearborn Heights School District #7',
    LEA_STATE: 'MI',
    RR_AM_F_WH_F_MR: null,
    RR_AM_M_POP_MR: 0,
    RR_AM_M_WH_M_MR: 0,
    RR_AS_F_POP_MR: 0,
    RR_AS_F_WH_F_MR: null,
    RR_AS_M_POP_MR: null,
    RR_AS_M_WH_M_MR: null,
    RR_BL_F_POP_MR: 2.585858586,
    RR_BL_F_WH_F_MR: null,
    RR_BL_M_POP_MR: 2.630769231,
    RR_BL_M_WH_M_MR: 1.438461538,
    RR_HI_F_POP_MR: 0,
    RR_HI_F_WH_F_MR: null,
    RR_HI_M_POP_MR: 0,
    RR_HI_M_WH_M_MR: 0,
    RR_HP_F_POP_MR: null,
    RR_HP_F_WH_F_MR: null,
    RR_HP_M_POP_MR: 0,
    RR_HP_M_WH_M_MR: 0,
    RR_TR_F_POP_MR: 0,
    RR_TR_F_WH_F_MR: null,
    RR_TR_M_POP_MR: 0,
    RR_TR_M_WH_M_MR: 0,
    RR_WH_F_POP_MR: 0,
    RR_WH_M_POP_MR: 2.090909091,
    SCHOOL_NAME: 'Oakley W Best Middle School',
    __typename: 'Intellectualdisability',
  },
  {
    COMBOKEY: '484404004994',
    LEA_NAME: 'VERIBEST ISD',
    LEA_STATE: 'TX',
    RR_AM_F_WH_F_MR: null,
    RR_AM_M_POP_MR: null,
    RR_AM_M_WH_M_MR: null,
    RR_AS_F_POP_MR: null,
    RR_AS_F_WH_F_MR: null,
    RR_AS_M_POP_MR: null,
    RR_AS_M_WH_M_MR: null,
    RR_BL_F_POP_MR: null,
    RR_BL_F_WH_F_MR: null,
    RR_BL_M_POP_MR: null,
    RR_BL_M_WH_M_MR: null,
    RR_HI_F_POP_MR: 0,
    RR_HI_F_WH_F_MR: null,
    RR_HI_M_POP_MR: 3.862068966,
    RR_HI_M_WH_M_MR: 1.206896552,
    RR_HP_F_POP_MR: null,
    RR_HP_F_WH_F_MR: null,
    RR_HP_M_POP_MR: null,
    RR_HP_M_WH_M_MR: null,
    RR_TR_F_POP_MR: 0,
    RR_TR_F_WH_F_MR: null,
    RR_TR_M_POP_MR: 0,
    RR_TR_M_WH_M_MR: 0,
    RR_WH_F_POP_MR: 0,
    RR_WH_M_POP_MR: 3.028571429,
    SCHOOL_NAME: 'VERIBEST EL',
    __typename: 'Intellectualdisability',
  },
  {
    COMBOKEY: '484404007463',
    LEA_NAME: 'VERIBEST ISD',
    LEA_STATE: 'TX',
    RR_AM_F_WH_F_MR: 0,
    RR_AM_M_POP_MR: null,
    RR_AM_M_WH_M_MR: null,
    RR_AS_F_POP_MR: 0,
    RR_AS_F_WH_F_MR: 0,
    RR_AS_M_POP_MR: 0,
    RR_AS_M_WH_M_MR: null,
    RR_BL_F_POP_MR: 0,
    RR_BL_F_WH_F_MR: 0,
    RR_BL_M_POP_MR: null,
    RR_BL_M_WH_M_MR: null,
    RR_HI_F_POP_MR: 0,
    RR_HI_F_WH_F_MR: 0,
    RR_HI_M_POP_MR: 0,
    RR_HI_M_WH_M_MR: null,
    RR_HP_F_POP_MR: null,
    RR_HP_F_WH_F_MR: null,
    RR_HP_M_POP_MR: null,
    RR_HP_M_WH_M_MR: null,
    RR_TR_F_POP_MR: 0,
    RR_TR_F_WH_F_MR: 0,
    RR_TR_M_POP_MR: 0,
    RR_TR_M_WH_M_MR: null,
    RR_WH_F_POP_MR: null,
    RR_WH_M_POP_MR: 0,
    SCHOOL_NAME: 'VERIBEST H S',
    __typename: 'Intellectualdisability',
  },
];

export default SCHOOLS_BY_NAME_MR_RESPONSE;
