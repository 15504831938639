const SCHOOLS_BY_NAME_AUT_RESPONSE = [
  {
    COMBOKEY: '480001106201',
    LEA_NAME: 'ALVARADO ISD',
    LEA_STATE: 'TX',
    RR_AM_F_WH_F_AUT: null,
    RR_AM_M_POP_AUT: 0,
    RR_AM_M_WH_M_AUT: 0,
    RR_AS_F_POP_AUT: null,
    RR_AS_F_WH_F_AUT: null,
    RR_AS_M_POP_AUT: 0,
    RR_AS_M_WH_M_AUT: 0,
    RR_BL_F_POP_AUT: 0,
    RR_BL_F_WH_F_AUT: 0,
    RR_BL_M_POP_AUT: null,
    RR_BL_M_WH_M_AUT: 0,
    RR_HI_F_POP_AUT: 0,
    RR_HI_F_WH_F_AUT: 0,
    RR_HI_M_POP_AUT: 3.039215686,
    RR_HI_M_WH_M_AUT: 4.62745098,
    RR_HP_F_POP_AUT: 0,
    RR_HP_F_WH_F_AUT: null,
    RR_HP_M_POP_AUT: null,
    RR_HP_M_WH_M_AUT: null,
    RR_TR_F_POP_AUT: 0,
    RR_TR_F_WH_F_AUT: 0,
    RR_TR_M_POP_AUT: 0,
    RR_TR_M_WH_M_AUT: 0,
    RR_WH_F_POP_AUT: 0,
    RR_WH_M_POP_AUT: 3.776,
    SCHOOL_NAME: 'ALVARADO EL-NORTH',
    __typename: 'Autism',
  },
  {
    COMBOKEY: '420210000407',
    LEA_NAME: 'Albert Gallatin Area SD',
    LEA_STATE: 'PA',
    RR_AM_F_WH_F_AUT: null,
    RR_AM_M_POP_AUT: null,
    RR_AM_M_WH_M_AUT: null,
    RR_AS_F_POP_AUT: 0,
    RR_AS_F_WH_F_AUT: 0,
    RR_AS_M_POP_AUT: null,
    RR_AS_M_WH_M_AUT: null,
    RR_BL_F_POP_AUT: 0,
    RR_BL_F_WH_F_AUT: 0,
    RR_BL_M_POP_AUT: null,
    RR_BL_M_WH_M_AUT: null,
    RR_HI_F_POP_AUT: 0,
    RR_HI_F_WH_F_AUT: 0,
    RR_HI_M_POP_AUT: 0,
    RR_HI_M_WH_M_AUT: null,
    RR_HP_F_POP_AUT: null,
    RR_HP_F_WH_F_AUT: null,
    RR_HP_M_POP_AUT: null,
    RR_HP_M_WH_M_AUT: null,
    RR_TR_F_POP_AUT: 0,
    RR_TR_F_WH_F_AUT: 0,
    RR_TR_M_POP_AUT: 0,
    RR_TR_M_WH_M_AUT: null,
    RR_WH_F_POP_AUT: 0,
    RR_WH_M_POP_AUT: null,
    SCHOOL_NAME: 'Albert Gallatin North MS',
    __typename: 'Autism',
  },
  {
    COMBOKEY: '320000100842',
    LEA_NAME: 'STATE-SPONSORED CHARTER SCHOOLS',
    LEA_STATE: 'NV',
    RR_AM_F_WH_F_AUT: 0,
    RR_AM_M_POP_AUT: 0,
    RR_AM_M_WH_M_AUT: 0,
    RR_AS_F_POP_AUT: 0,
    RR_AS_F_WH_F_AUT: 0,
    RR_AS_M_POP_AUT: 0,
    RR_AS_M_WH_M_AUT: 0,
    RR_BL_F_POP_AUT: 2.131313131,
    RR_BL_F_WH_F_AUT: 1.585858586,
    RR_BL_M_POP_AUT: null,
    RR_BL_M_WH_M_AUT: 5.059459459,
    RR_HI_F_POP_AUT: 0,
    RR_HI_F_WH_F_AUT: 0,
    RR_HI_M_POP_AUT: 0.914975845,
    RR_HI_M_WH_M_AUT: 0.904347826,
    RR_HP_F_POP_AUT: 0,
    RR_HP_F_WH_F_AUT: null,
    RR_HP_M_POP_AUT: 0,
    RR_HP_M_WH_M_AUT: 0,
    RR_TR_F_POP_AUT: 0,
    RR_TR_F_WH_F_AUT: 0,
    RR_TR_M_POP_AUT: 0,
    RR_TR_M_WH_M_AUT: 0,
    RR_WH_F_POP_AUT: 0,
    RR_WH_M_POP_AUT: 0.858715596,
    SCHOOL_NAME: 'American Leadership Academy North Las Vegas',
    __typename: 'Autism',
  },
  {
    COMBOKEY: '260279000196',
    LEA_NAME: 'Anchor Bay School District',
    LEA_STATE: 'MI',
    RR_AM_F_WH_F_AUT: null,
    RR_AM_M_POP_AUT: 0,
    RR_AM_M_WH_M_AUT: 0,
    RR_AS_F_POP_AUT: 0,
    RR_AS_F_WH_F_AUT: 0,
    RR_AS_M_POP_AUT: 0,
    RR_AS_M_WH_M_AUT: 0,
    RR_BL_F_POP_AUT: 0,
    RR_BL_F_WH_F_AUT: 0,
    RR_BL_M_POP_AUT: null,
    RR_BL_M_WH_M_AUT: 18.26923077,
    RR_HI_F_POP_AUT: 0,
    RR_HI_F_WH_F_AUT: 0,
    RR_HI_M_POP_AUT: 0,
    RR_HI_M_WH_M_AUT: 0,
    RR_HP_F_POP_AUT: 0,
    RR_HP_F_WH_F_AUT: null,
    RR_HP_M_POP_AUT: null,
    RR_HP_M_WH_M_AUT: null,
    RR_TR_F_POP_AUT: 0,
    RR_TR_F_WH_F_AUT: 0,
    RR_TR_M_POP_AUT: 0,
    RR_TR_M_WH_M_AUT: 0,
    RR_WH_F_POP_AUT: 0.111527648,
    RR_WH_M_POP_AUT: 6.105398458,
    SCHOOL_NAME: 'Anchor Bay Middle School North',
    __typename: 'Autism',
  },
  {
    COMBOKEY: '180027002450',
    LEA_NAME: 'Avon Community School Corp',
    LEA_STATE: 'IN',
    RR_AM_F_WH_F_AUT: 0,
    RR_AM_M_POP_AUT: null,
    RR_AM_M_WH_M_AUT: null,
    RR_AS_F_POP_AUT: 0,
    RR_AS_F_WH_F_AUT: 0,
    RR_AS_M_POP_AUT: 1.945,
    RR_AS_M_WH_M_AUT: 3.48,
    RR_BL_F_POP_AUT: 1.132440476,
    RR_BL_F_WH_F_AUT: 0.833333333,
    RR_BL_M_POP_AUT: null,
    RR_BL_M_WH_M_AUT: 1.45,
    RR_HI_F_POP_AUT: 0,
    RR_HI_F_WH_F_AUT: 0,
    RR_HI_M_POP_AUT: 1.052777778,
    RR_HI_M_WH_M_AUT: 1.933333333,
    RR_HP_F_POP_AUT: null,
    RR_HP_F_WH_F_AUT: null,
    RR_HP_M_POP_AUT: null,
    RR_HP_M_WH_M_AUT: null,
    RR_TR_F_POP_AUT: 2.119565217,
    RR_TR_F_WH_F_AUT: 1.52173913,
    RR_TR_M_POP_AUT: 0,
    RR_TR_M_WH_M_AUT: 0,
    RR_WH_F_POP_AUT: 0.144032922,
    RR_WH_M_POP_AUT: 3.40569395,
    SCHOOL_NAME: 'Avon Middle School North',
    __typename: 'Autism',
  },
  {
    COMBOKEY: '480945012941',
    LEA_NAME: 'BARBERS HILL ISD',
    LEA_STATE: 'TX',
    RR_AM_F_WH_F_AUT: 0,
    RR_AM_M_POP_AUT: 0,
    RR_AM_M_WH_M_AUT: 0,
    RR_AS_F_POP_AUT: 0,
    RR_AS_F_WH_F_AUT: 0,
    RR_AS_M_POP_AUT: 0,
    RR_AS_M_WH_M_AUT: 0,
    RR_BL_F_POP_AUT: 0,
    RR_BL_F_WH_F_AUT: 0,
    RR_BL_M_POP_AUT: null,
    RR_BL_M_WH_M_AUT: 11.75,
    RR_HI_F_POP_AUT: 0,
    RR_HI_F_WH_F_AUT: 0,
    RR_HI_M_POP_AUT: 0,
    RR_HI_M_WH_M_AUT: 0,
    RR_HP_F_POP_AUT: null,
    RR_HP_F_WH_F_AUT: null,
    RR_HP_M_POP_AUT: null,
    RR_HP_M_WH_M_AUT: null,
    RR_TR_F_POP_AUT: 0,
    RR_TR_F_WH_F_AUT: 0,
    RR_TR_M_POP_AUT: 8.711111111,
    RR_TR_M_WH_M_AUT: 12.925,
    RR_WH_F_POP_AUT: 0.409468439,
    RR_WH_M_POP_AUT: 2.799638989,
    SCHOOL_NAME: 'BARBERS HILL EL NORTH',
    __typename: 'Autism',
  },
  {
    COMBOKEY: '480945013042',
    LEA_NAME: 'BARBERS HILL ISD',
    LEA_STATE: 'TX',
    RR_AM_F_WH_F_AUT: 0,
    RR_AM_M_POP_AUT: null,
    RR_AM_M_WH_M_AUT: null,
    RR_AS_F_POP_AUT: 0,
    RR_AS_F_WH_F_AUT: 0,
    RR_AS_M_POP_AUT: 0,
    RR_AS_M_WH_M_AUT: 0,
    RR_BL_F_POP_AUT: 0,
    RR_BL_F_WH_F_AUT: 0,
    RR_BL_M_POP_AUT: null,
    RR_BL_M_WH_M_AUT: 0,
    RR_HI_F_POP_AUT: 0,
    RR_HI_F_WH_F_AUT: 0,
    RR_HI_M_POP_AUT: 1.320707071,
    RR_HI_M_WH_M_AUT: 2.818181818,
    RR_HP_F_POP_AUT: null,
    RR_HP_F_WH_F_AUT: null,
    RR_HP_M_POP_AUT: null,
    RR_HP_M_WH_M_AUT: null,
    RR_TR_F_POP_AUT: 0,
    RR_TR_F_WH_F_AUT: 0,
    RR_TR_M_POP_AUT: 0,
    RR_TR_M_WH_M_AUT: 0,
    RR_WH_F_POP_AUT: 0.331641286,
    RR_WH_M_POP_AUT: 4.285714286,
    SCHOOL_NAME: 'BARBERS HILL MIDDLE NORTH',
    __typename: 'Autism',
  },
  {
    COMBOKEY: '040095803534',
    LEA_NAME: 'BASIS School Inc. 18',
    LEA_STATE: 'AZ',
    RR_AM_F_WH_F_AUT: 0,
    RR_AM_M_POP_AUT: 0,
    RR_AM_M_WH_M_AUT: 0,
    RR_AS_F_POP_AUT: 0,
    RR_AS_F_WH_F_AUT: 0,
    RR_AS_M_POP_AUT: 2.65408805,
    RR_AS_M_WH_M_AUT: 3.245283019,
    RR_BL_F_POP_AUT: 0,
    RR_BL_F_WH_F_AUT: 0,
    RR_BL_M_POP_AUT: null,
    RR_BL_M_WH_M_AUT: 0,
    RR_HI_F_POP_AUT: 0,
    RR_HI_F_WH_F_AUT: 0,
    RR_HI_M_POP_AUT: 0,
    RR_HI_M_WH_M_AUT: 0,
    RR_HP_F_POP_AUT: null,
    RR_HP_F_WH_F_AUT: null,
    RR_HP_M_POP_AUT: null,
    RR_HP_M_WH_M_AUT: null,
    RR_TR_F_POP_AUT: 0,
    RR_TR_F_WH_F_AUT: 0,
    RR_TR_M_POP_AUT: 0,
    RR_TR_M_WH_M_AUT: 0,
    RR_WH_F_POP_AUT: 0,
    RR_WH_M_POP_AUT: 7.938461538,
    SCHOOL_NAME: 'BASIS Chandler Primary - North Campus',
    __typename: 'Autism',
  },
  {
    COMBOKEY: '480143813202',
    LEA_NAME: 'BASIS TEXAS',
    LEA_STATE: 'TX',
    RR_AM_F_WH_F_AUT: null,
    RR_AM_M_POP_AUT: 0,
    RR_AM_M_WH_M_AUT: 0,
    RR_AS_F_POP_AUT: 1.985815603,
    RR_AS_F_WH_F_AUT: 1.295212766,
    RR_AS_M_POP_AUT: 2.573333333,
    RR_AS_M_WH_M_AUT: 2.053333333,
    RR_BL_F_POP_AUT: 0,
    RR_BL_F_WH_F_AUT: 0,
    RR_BL_M_POP_AUT: null,
    RR_BL_M_WH_M_AUT: 10.26666667,
    RR_HI_F_POP_AUT: 0,
    RR_HI_F_WH_F_AUT: 0,
    RR_HI_M_POP_AUT: 0,
    RR_HI_M_WH_M_AUT: 0,
    RR_HP_F_POP_AUT: null,
    RR_HP_F_WH_F_AUT: null,
    RR_HP_M_POP_AUT: null,
    RR_HP_M_WH_M_AUT: null,
    RR_TR_F_POP_AUT: 0,
    RR_TR_F_WH_F_AUT: 0,
    RR_TR_M_POP_AUT: 0,
    RR_TR_M_WH_M_AUT: 0,
    RR_WH_F_POP_AUT: 0,
    RR_WH_M_POP_AUT: 0.802083333,
    SCHOOL_NAME: 'BASIS SAN ANTONIO- PRI NORTH CENTRAL CAMPUS',
    __typename: 'Autism',
  },
  {
    COMBOKEY: '040087803350',
    LEA_NAME: 'BASIS School Inc. 8',
    LEA_STATE: 'AZ',
    RR_AM_F_WH_F_AUT: 0,
    RR_AM_M_POP_AUT: 0,
    RR_AM_M_WH_M_AUT: 0,
    RR_AS_F_POP_AUT: 0,
    RR_AS_F_WH_F_AUT: 0,
    RR_AS_M_POP_AUT: 0,
    RR_AS_M_WH_M_AUT: 0,
    RR_BL_F_POP_AUT: 0,
    RR_BL_F_WH_F_AUT: 0,
    RR_BL_M_POP_AUT: null,
    RR_BL_M_WH_M_AUT: 55.53846154,
    RR_HI_F_POP_AUT: 0,
    RR_HI_F_WH_F_AUT: 0,
    RR_HI_M_POP_AUT: 0,
    RR_HI_M_WH_M_AUT: 0,
    RR_HP_F_POP_AUT: 0,
    RR_HP_F_WH_F_AUT: null,
    RR_HP_M_POP_AUT: null,
    RR_HP_M_WH_M_AUT: null,
    RR_TR_F_POP_AUT: 0,
    RR_TR_F_WH_F_AUT: 0,
    RR_TR_M_POP_AUT: 0,
    RR_TR_M_WH_M_AUT: 0,
    RR_WH_F_POP_AUT: 0,
    RR_WH_M_POP_AUT: 2.694029851,
    SCHOOL_NAME: 'BASIS Tucson North',
    __typename: 'Autism',
  },
];

export default SCHOOLS_BY_NAME_AUT_RESPONSE;
