const SCHOOLS_BY_NAME_VI_RESPONSE = [
  {
    COMBOKEY: '390030504842',
    LEA_NAME: 'A+ Arts Academy',
    LEA_STATE: 'OH',
    RR_AM_F_WH_F_VI: null,
    RR_AM_M_POP_VI: null,
    RR_AM_M_WH_M_VI: null,
    RR_AS_F_POP_VI: null,
    RR_AS_F_WH_F_VI: null,
    RR_AS_M_POP_VI: null,
    RR_AS_M_WH_M_VI: null,
    RR_BL_F_POP_VI: 0,
    RR_BL_F_WH_F_VI: null,
    RR_BL_M_POP_VI: null,
    RR_BL_M_WH_M_VI: null,
    RR_HI_F_POP_VI: 0,
    RR_HI_F_WH_F_VI: null,
    RR_HI_M_POP_VI: 0,
    RR_HI_M_WH_M_VI: null,
    RR_HP_F_POP_VI: null,
    RR_HP_F_WH_F_VI: null,
    RR_HP_M_POP_VI: null,
    RR_HP_M_WH_M_VI: null,
    RR_TR_F_POP_VI: 0,
    RR_TR_F_WH_F_VI: null,
    RR_TR_M_POP_VI: 0,
    RR_TR_M_WH_M_VI: null,
    RR_WH_F_POP_VI: 0,
    RR_WH_M_POP_VI: 0,
    SCHOOL_NAME: 'A+ Arts Academy',
    __typename: 'Visualimpairment',
  },
  {
    COMBOKEY: '050005500513',
    LEA_NAME: 'BENTON COUNTY SCHOOL OF ARTS',
    LEA_STATE: 'AR',
    RR_AM_F_WH_F_VI: null,
    RR_AM_M_POP_VI: null,
    RR_AM_M_WH_M_VI: null,
    RR_AS_F_POP_VI: null,
    RR_AS_F_WH_F_VI: null,
    RR_AS_M_POP_VI: null,
    RR_AS_M_WH_M_VI: null,
    RR_BL_F_POP_VI: null,
    RR_BL_F_WH_F_VI: null,
    RR_BL_M_POP_VI: null,
    RR_BL_M_WH_M_VI: null,
    RR_HI_F_POP_VI: null,
    RR_HI_F_WH_F_VI: null,
    RR_HI_M_POP_VI: null,
    RR_HI_M_WH_M_VI: null,
    RR_HP_F_POP_VI: null,
    RR_HP_F_WH_F_VI: null,
    RR_HP_M_POP_VI: null,
    RR_HP_M_WH_M_VI: null,
    RR_TR_F_POP_VI: null,
    RR_TR_F_WH_F_VI: null,
    RR_TR_M_POP_VI: null,
    RR_TR_M_WH_M_VI: null,
    RR_WH_F_POP_VI: null,
    RR_WH_M_POP_VI: null,
    SCHOOL_NAME: 'ARKANSAS ARTS ACADEMY ELEMENTARY/MIDDLE SCHOOL',
    __typename: 'Visualimpairment',
  },
  {
    COMBOKEY: '050005501508',
    LEA_NAME: 'BENTON COUNTY SCHOOL OF ARTS',
    LEA_STATE: 'AR',
    RR_AM_F_WH_F_VI: null,
    RR_AM_M_POP_VI: null,
    RR_AM_M_WH_M_VI: null,
    RR_AS_F_POP_VI: null,
    RR_AS_F_WH_F_VI: null,
    RR_AS_M_POP_VI: null,
    RR_AS_M_WH_M_VI: null,
    RR_BL_F_POP_VI: null,
    RR_BL_F_WH_F_VI: null,
    RR_BL_M_POP_VI: null,
    RR_BL_M_WH_M_VI: null,
    RR_HI_F_POP_VI: null,
    RR_HI_F_WH_F_VI: null,
    RR_HI_M_POP_VI: null,
    RR_HI_M_WH_M_VI: null,
    RR_HP_F_POP_VI: null,
    RR_HP_F_WH_F_VI: null,
    RR_HP_M_POP_VI: null,
    RR_HP_M_WH_M_VI: null,
    RR_TR_F_POP_VI: null,
    RR_TR_F_WH_F_VI: null,
    RR_TR_M_POP_VI: null,
    RR_TR_M_WH_M_VI: null,
    RR_WH_F_POP_VI: null,
    RR_WH_M_POP_VI: null,
    SCHOOL_NAME: 'ARKANSAS ARTS ACADEMY HIGH SCHOOL',
    __typename: 'Visualimpairment',
  },
  {
    COMBOKEY: '350057000209',
    LEA_NAME: 'CLOVIS MUNICIPAL SCHOOLS',
    LEA_STATE: 'NM',
    RR_AM_F_WH_F_VI: 0,
    RR_AM_M_POP_VI: 0,
    RR_AM_M_WH_M_VI: null,
    RR_AS_F_POP_VI: null,
    RR_AS_F_WH_F_VI: null,
    RR_AS_M_POP_VI: null,
    RR_AS_M_WH_M_VI: null,
    RR_BL_F_POP_VI: 0,
    RR_BL_F_WH_F_VI: 0,
    RR_BL_M_POP_VI: 0,
    RR_BL_M_WH_M_VI: null,
    RR_HI_F_POP_VI: 0,
    RR_HI_F_WH_F_VI: 0,
    RR_HI_M_POP_VI: 0,
    RR_HI_M_WH_M_VI: null,
    RR_HP_F_POP_VI: null,
    RR_HP_F_WH_F_VI: null,
    RR_HP_M_POP_VI: 0,
    RR_HP_M_WH_M_VI: null,
    RR_TR_F_POP_VI: 0,
    RR_TR_F_WH_F_VI: 0,
    RR_TR_M_POP_VI: null,
    RR_TR_M_WH_M_VI: null,
    RR_WH_F_POP_VI: null,
    RR_WH_M_POP_VI: 0,
    SCHOOL_NAME: 'ARTS ACADEMY AT BELLA VISTA',
    __typename: 'Visualimpairment',
  },
  {
    COMBOKEY: '062271013079',
    LEA_NAME: 'Los Angeles Unified',
    LEA_STATE: 'CA',
    RR_AM_F_WH_F_VI: null,
    RR_AM_M_POP_VI: null,
    RR_AM_M_WH_M_VI: null,
    RR_AS_F_POP_VI: 0,
    RR_AS_F_WH_F_VI: null,
    RR_AS_M_POP_VI: 0,
    RR_AS_M_WH_M_VI: null,
    RR_BL_F_POP_VI: 0,
    RR_BL_F_WH_F_VI: null,
    RR_BL_M_POP_VI: 0,
    RR_BL_M_WH_M_VI: null,
    RR_HI_F_POP_VI: null,
    RR_HI_F_WH_F_VI: null,
    RR_HI_M_POP_VI: 0,
    RR_HI_M_WH_M_VI: null,
    RR_HP_F_POP_VI: null,
    RR_HP_F_WH_F_VI: null,
    RR_HP_M_POP_VI: null,
    RR_HP_M_WH_M_VI: null,
    RR_TR_F_POP_VI: null,
    RR_TR_F_WH_F_VI: null,
    RR_TR_M_POP_VI: 0,
    RR_TR_M_WH_M_VI: null,
    RR_WH_F_POP_VI: 0,
    RR_WH_M_POP_VI: 0,
    SCHOOL_NAME: 'Acad for Multilingual Arts and Sci at Mervyn M. Dymally High',
    __typename: 'Visualimpairment',
  },
  {
    COMBOKEY: '040023902533',
    LEA_NAME: 'P.L.C. Charter Schools',
    LEA_STATE: 'AZ',
    RR_AM_F_WH_F_VI: null,
    RR_AM_M_POP_VI: 0,
    RR_AM_M_WH_M_VI: null,
    RR_AS_F_POP_VI: 0,
    RR_AS_F_WH_F_VI: null,
    RR_AS_M_POP_VI: 0,
    RR_AS_M_WH_M_VI: null,
    RR_BL_F_POP_VI: 0,
    RR_BL_F_WH_F_VI: null,
    RR_BL_M_POP_VI: 0,
    RR_BL_M_WH_M_VI: null,
    RR_HI_F_POP_VI: null,
    RR_HI_F_WH_F_VI: null,
    RR_HI_M_POP_VI: 0,
    RR_HI_M_WH_M_VI: null,
    RR_HP_F_POP_VI: null,
    RR_HP_F_WH_F_VI: null,
    RR_HP_M_POP_VI: 0,
    RR_HP_M_WH_M_VI: null,
    RR_TR_F_POP_VI: 0,
    RR_TR_F_WH_F_VI: null,
    RR_TR_M_POP_VI: 0,
    RR_TR_M_WH_M_VI: null,
    RR_WH_F_POP_VI: 0,
    RR_WH_M_POP_VI: 0,
    SCHOOL_NAME: 'Arts Academy at Estrella Mountain',
    __typename: 'Visualimpairment',
  },
  {
    COMBOKEY: '411317001786',
    LEA_NAME: 'West Linn-Wilsonville SD 3J',
    LEA_STATE: 'OR',
    RR_AM_F_WH_F_VI: null,
    RR_AM_M_POP_VI: null,
    RR_AM_M_WH_M_VI: null,
    RR_AS_F_POP_VI: null,
    RR_AS_F_WH_F_VI: null,
    RR_AS_M_POP_VI: null,
    RR_AS_M_WH_M_VI: null,
    RR_BL_F_POP_VI: null,
    RR_BL_F_WH_F_VI: null,
    RR_BL_M_POP_VI: 0,
    RR_BL_M_WH_M_VI: 0,
    RR_HI_F_POP_VI: 0,
    RR_HI_F_WH_F_VI: null,
    RR_HI_M_POP_VI: 0,
    RR_HI_M_WH_M_VI: 0,
    RR_HP_F_POP_VI: null,
    RR_HP_F_WH_F_VI: null,
    RR_HP_M_POP_VI: null,
    RR_HP_M_WH_M_VI: null,
    RR_TR_F_POP_VI: 0,
    RR_TR_F_WH_F_VI: null,
    RR_TR_M_POP_VI: 0,
    RR_TR_M_WH_M_VI: 0,
    RR_WH_F_POP_VI: 0,
    RR_WH_M_POP_VI: null,
    SCHOOL_NAME: 'Arts and Technology High School',
    __typename: 'Visualimpairment',
  },
  {
    COMBOKEY: '063441001276',
    LEA_NAME: 'San Francisco Unified',
    LEA_STATE: 'CA',
    RR_AM_F_WH_F_VI: 0,
    RR_AM_M_POP_VI: null,
    RR_AM_M_WH_M_VI: null,
    RR_AS_F_POP_VI: 0,
    RR_AS_F_WH_F_VI: 0,
    RR_AS_M_POP_VI: 0,
    RR_AS_M_WH_M_VI: null,
    RR_BL_F_POP_VI: 0,
    RR_BL_F_WH_F_VI: 0,
    RR_BL_M_POP_VI: 0,
    RR_BL_M_WH_M_VI: null,
    RR_HI_F_POP_VI: 0,
    RR_HI_F_WH_F_VI: 0,
    RR_HI_M_POP_VI: 0,
    RR_HI_M_WH_M_VI: null,
    RR_HP_F_POP_VI: 0,
    RR_HP_F_WH_F_VI: 0,
    RR_HP_M_POP_VI: 0,
    RR_HP_M_WH_M_VI: null,
    RR_TR_F_POP_VI: 0,
    RR_TR_F_WH_F_VI: 0,
    RR_TR_M_POP_VI: 0,
    RR_TR_M_WH_M_VI: null,
    RR_WH_F_POP_VI: null,
    RR_WH_M_POP_VI: 0,
    SCHOOL_NAME: 'Asawa (Ruth) SF Sch of the Arts, A Public School',
    __typename: 'Visualimpairment',
  },
  {
    COMBOKEY: '120150003226',
    LEA_NAME: 'PALM BEACH',
    LEA_STATE: 'FL',
    RR_AM_F_WH_F_VI: 0,
    RR_AM_M_POP_VI: 0,
    RR_AM_M_WH_M_VI: null,
    RR_AS_F_POP_VI: 0,
    RR_AS_F_WH_F_VI: 0,
    RR_AS_M_POP_VI: 0,
    RR_AS_M_WH_M_VI: null,
    RR_BL_F_POP_VI: 0,
    RR_BL_F_WH_F_VI: 0,
    RR_BL_M_POP_VI: 0,
    RR_BL_M_WH_M_VI: null,
    RR_HI_F_POP_VI: 0,
    RR_HI_F_WH_F_VI: 0,
    RR_HI_M_POP_VI: 0,
    RR_HI_M_WH_M_VI: null,
    RR_HP_F_POP_VI: null,
    RR_HP_F_WH_F_VI: null,
    RR_HP_M_POP_VI: 0,
    RR_HP_M_WH_M_VI: null,
    RR_TR_F_POP_VI: 0,
    RR_TR_F_WH_F_VI: 0,
    RR_TR_M_POP_VI: 0,
    RR_TR_M_WH_M_VI: null,
    RR_WH_F_POP_VI: null,
    RR_WH_M_POP_VI: 0,
    SCHOOL_NAME: 'BAK MIDDLE SCHOOL OF THE ARTS',
    __typename: 'Visualimpairment',
  },
  {
    COMBOKEY: '120108001160',
    LEA_NAME: 'LEE',
    LEA_STATE: 'FL',
    RR_AM_F_WH_F_VI: null,
    RR_AM_M_POP_VI: 0,
    RR_AM_M_WH_M_VI: null,
    RR_AS_F_POP_VI: 0,
    RR_AS_F_WH_F_VI: null,
    RR_AS_M_POP_VI: 0,
    RR_AS_M_WH_M_VI: null,
    RR_BL_F_POP_VI: 0,
    RR_BL_F_WH_F_VI: null,
    RR_BL_M_POP_VI: null,
    RR_BL_M_WH_M_VI: null,
    RR_HI_F_POP_VI: 0,
    RR_HI_F_WH_F_VI: null,
    RR_HI_M_POP_VI: 0,
    RR_HI_M_WH_M_VI: null,
    RR_HP_F_POP_VI: null,
    RR_HP_F_WH_F_VI: null,
    RR_HP_M_POP_VI: null,
    RR_HP_M_WH_M_VI: null,
    RR_TR_F_POP_VI: 0,
    RR_TR_F_WH_F_VI: null,
    RR_TR_M_POP_VI: 0,
    RR_TR_M_WH_M_VI: null,
    RR_WH_F_POP_VI: 0,
    RR_WH_M_POP_VI: 0,
    SCHOOL_NAME: 'BONITA SPRINGS MIDDLE CENTER FOR THE ARTS',
    __typename: 'Visualimpairment',
  },
];

export default SCHOOLS_BY_NAME_VI_RESPONSE;
