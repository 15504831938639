const SCHOOLS_BY_NAME_SLI_RESPONSE = [
  {
    COMBOKEY: '010237000954',
    LEA_NAME: 'Mobile County',
    LEA_STATE: 'AL',
    RR_AM_F_WH_F_SLI: null,
    RR_AM_M_POP_SLI: 0,
    RR_AM_M_WH_M_SLI: 0,
    RR_AS_F_POP_SLI: null,
    RR_AS_F_WH_F_SLI: null,
    RR_AS_M_POP_SLI: null,
    RR_AS_M_WH_M_SLI: null,
    RR_BL_F_POP_SLI: 0.752118644,
    RR_BL_F_WH_F_SLI: null,
    RR_BL_M_POP_SLI: 0.488294314,
    RR_BL_M_WH_M_SLI: 0.030100334,
    RR_HI_F_POP_SLI: 0,
    RR_HI_F_WH_F_SLI: null,
    RR_HI_M_POP_SLI: 0,
    RR_HI_M_WH_M_SLI: 0,
    RR_HP_F_POP_SLI: null,
    RR_HP_F_WH_F_SLI: null,
    RR_HP_M_POP_SLI: null,
    RR_HP_M_WH_M_SLI: null,
    RR_TR_F_POP_SLI: 0,
    RR_TR_F_WH_F_SLI: null,
    RR_TR_M_POP_SLI: 0,
    RR_TR_M_WH_M_SLI: 0,
    RR_WH_F_POP_SLI: 0,
    RR_WH_M_POP_SLI: 32.33333333,
    SCHOOL_NAME: 'CL Scarborough Model Middle School',
    __typename: 'Speechlanguageimpairment',
  },
  {
    COMBOKEY: '360008605666',
    LEA_NAME: 'NEW YORK CITY GEOGRAPHIC DISTRICT # 9',
    LEA_STATE: 'NY',
    RR_AM_F_WH_F_SLI: null,
    RR_AM_M_POP_SLI: null,
    RR_AM_M_WH_M_SLI: null,
    RR_AS_F_POP_SLI: null,
    RR_AS_F_WH_F_SLI: null,
    RR_AS_M_POP_SLI: null,
    RR_AS_M_WH_M_SLI: null,
    RR_BL_F_POP_SLI: null,
    RR_BL_F_WH_F_SLI: null,
    RR_BL_M_POP_SLI: null,
    RR_BL_M_WH_M_SLI: null,
    RR_HI_F_POP_SLI: null,
    RR_HI_F_WH_F_SLI: null,
    RR_HI_M_POP_SLI: null,
    RR_HI_M_WH_M_SLI: null,
    RR_HP_F_POP_SLI: null,
    RR_HP_F_WH_F_SLI: null,
    RR_HP_M_POP_SLI: null,
    RR_HP_M_WH_M_SLI: null,
    RR_TR_F_POP_SLI: null,
    RR_TR_F_WH_F_SLI: null,
    RR_TR_M_POP_SLI: null,
    RR_TR_M_WH_M_SLI: null,
    RR_WH_F_POP_SLI: null,
    RR_WH_M_POP_SLI: null,
    SCHOOL_NAME: 'COMPREHENSIVE MODEL SCHOOL PROJECT MS 327',
    __typename: 'Speechlanguageimpairment',
  },
  {
    COMBOKEY: '370150002448',
    LEA_NAME: 'Winston Salem / Forsyth County Schools',
    LEA_STATE: 'NC',
    RR_AM_F_WH_F_SLI: null,
    RR_AM_M_POP_SLI: null,
    RR_AM_M_WH_M_SLI: null,
    RR_AS_F_POP_SLI: 0,
    RR_AS_F_WH_F_SLI: null,
    RR_AS_M_POP_SLI: 0,
    RR_AS_M_WH_M_SLI: null,
    RR_BL_F_POP_SLI: 0.910891089,
    RR_BL_F_WH_F_SLI: null,
    RR_BL_M_POP_SLI: 1.819444444,
    RR_BL_M_WH_M_SLI: null,
    RR_HI_F_POP_SLI: 0,
    RR_HI_F_WH_F_SLI: null,
    RR_HI_M_POP_SLI: 0,
    RR_HI_M_WH_M_SLI: null,
    RR_HP_F_POP_SLI: null,
    RR_HP_F_WH_F_SLI: null,
    RR_HP_M_POP_SLI: null,
    RR_HP_M_WH_M_SLI: null,
    RR_TR_F_POP_SLI: 0,
    RR_TR_F_WH_F_SLI: null,
    RR_TR_M_POP_SLI: 0,
    RR_TR_M_WH_M_SLI: null,
    RR_WH_F_POP_SLI: 0,
    RR_WH_M_POP_SLI: 0,
    SCHOOL_NAME: 'Cook Literacy Model School',
    __typename: 'Speechlanguageimpairment',
  },
  {
    COMBOKEY: '510027000087',
    LEA_NAME: 'ARLINGTON CO PBLC SCHS',
    LEA_STATE: 'VA',
    RR_AM_F_WH_F_SLI: 0,
    RR_AM_M_POP_SLI: 0,
    RR_AM_M_WH_M_SLI: 0,
    RR_AS_F_POP_SLI: 0,
    RR_AS_F_WH_F_SLI: 0,
    RR_AS_M_POP_SLI: 0,
    RR_AS_M_WH_M_SLI: 0,
    RR_BL_F_POP_SLI: 0.858585859,
    RR_BL_F_WH_F_SLI: 1.616161616,
    RR_BL_M_POP_SLI: 2.154121864,
    RR_BL_M_WH_M_SLI: 2.107526882,
    RR_HI_F_POP_SLI: 1.16187291,
    RR_HI_F_WH_F_SLI: 2.086956522,
    RR_HI_M_POP_SLI: 0.801360544,
    RR_HI_M_WH_M_SLI: 0.933333333,
    RR_HP_F_POP_SLI: null,
    RR_HP_F_WH_F_SLI: null,
    RR_HP_M_POP_SLI: 0,
    RR_HP_M_WH_M_SLI: 0,
    RR_TR_F_POP_SLI: 0,
    RR_TR_F_WH_F_SLI: 0,
    RR_TR_M_POP_SLI: 3.275862069,
    RR_TR_M_WH_M_SLI: 3.379310345,
    RR_WH_F_POP_SLI: 0.511666667,
    RR_WH_M_POP_SLI: 0.868804665,
    SCHOOL_NAME: 'Drew Model Elementary',
    __typename: 'Speechlanguageimpairment',
  },
  {
    COMBOKEY: '130219000943',
    LEA_NAME: 'Floyd County',
    LEA_STATE: 'GA',
    RR_AM_F_WH_F_SLI: null,
    RR_AM_M_POP_SLI: null,
    RR_AM_M_WH_M_SLI: null,
    RR_AS_F_POP_SLI: null,
    RR_AS_F_WH_F_SLI: null,
    RR_AS_M_POP_SLI: null,
    RR_AS_M_WH_M_SLI: null,
    RR_BL_F_POP_SLI: 0,
    RR_BL_F_WH_F_SLI: 0,
    RR_BL_M_POP_SLI: 0,
    RR_BL_M_WH_M_SLI: 0,
    RR_HI_F_POP_SLI: 0,
    RR_HI_F_WH_F_SLI: 0,
    RR_HI_M_POP_SLI: 0,
    RR_HI_M_WH_M_SLI: 0,
    RR_HP_F_POP_SLI: null,
    RR_HP_F_WH_F_SLI: null,
    RR_HP_M_POP_SLI: null,
    RR_HP_M_WH_M_SLI: null,
    RR_TR_F_POP_SLI: 0,
    RR_TR_F_WH_F_SLI: 0,
    RR_TR_M_POP_SLI: 0,
    RR_TR_M_WH_M_SLI: 0,
    RR_WH_F_POP_SLI: 0.259191176,
    RR_WH_M_POP_SLI: 5.525,
    SCHOOL_NAME: 'Model Elementary School',
    __typename: 'Speechlanguageimpairment',
  },
  {
    COMBOKEY: '180393000656',
    LEA_NAME: 'Goshen Community Schools',
    LEA_STATE: 'IN',
    RR_AM_F_WH_F_SLI: null,
    RR_AM_M_POP_SLI: 0,
    RR_AM_M_WH_M_SLI: 0,
    RR_AS_F_POP_SLI: 0,
    RR_AS_F_WH_F_SLI: 0,
    RR_AS_M_POP_SLI: 0,
    RR_AS_M_WH_M_SLI: 0,
    RR_BL_F_POP_SLI: 2.100358423,
    RR_BL_F_WH_F_SLI: 2.111111111,
    RR_BL_M_POP_SLI: 0,
    RR_BL_M_WH_M_SLI: 0,
    RR_HI_F_POP_SLI: 0.544518028,
    RR_HI_F_WH_F_SLI: 0.629139073,
    RR_HI_M_POP_SLI: 0.939130435,
    RR_HI_M_WH_M_SLI: 0.550285714,
    RR_HP_F_POP_SLI: null,
    RR_HP_F_WH_F_SLI: null,
    RR_HP_M_POP_SLI: null,
    RR_HP_M_WH_M_SLI: null,
    RR_TR_F_POP_SLI: 0,
    RR_TR_F_WH_F_SLI: 0,
    RR_TR_M_POP_SLI: 2.100358423,
    RR_TR_M_WH_M_SLI: 1.188888889,
    RR_WH_F_POP_SLI: 0.973684211,
    RR_WH_M_POP_SLI: 2.07306712,
    SCHOOL_NAME: 'Model Elementary School',
    __typename: 'Speechlanguageimpairment',
  },
  {
    COMBOKEY: '130219002373',
    LEA_NAME: 'Floyd County',
    LEA_STATE: 'GA',
    RR_AM_F_WH_F_SLI: 0,
    RR_AM_M_POP_SLI: null,
    RR_AM_M_WH_M_SLI: null,
    RR_AS_F_POP_SLI: 0,
    RR_AS_F_WH_F_SLI: 0,
    RR_AS_M_POP_SLI: 0,
    RR_AS_M_WH_M_SLI: null,
    RR_BL_F_POP_SLI: 0,
    RR_BL_F_WH_F_SLI: 0,
    RR_BL_M_POP_SLI: 0,
    RR_BL_M_WH_M_SLI: null,
    RR_HI_F_POP_SLI: 0,
    RR_HI_F_WH_F_SLI: 0,
    RR_HI_M_POP_SLI: 0,
    RR_HI_M_WH_M_SLI: null,
    RR_HP_F_POP_SLI: null,
    RR_HP_F_WH_F_SLI: null,
    RR_HP_M_POP_SLI: null,
    RR_HP_M_WH_M_SLI: null,
    RR_TR_F_POP_SLI: 0,
    RR_TR_F_WH_F_SLI: 0,
    RR_TR_M_POP_SLI: 0,
    RR_TR_M_WH_M_SLI: null,
    RR_WH_F_POP_SLI: null,
    RR_WH_M_POP_SLI: 0,
    SCHOOL_NAME: 'Model High',
    __typename: 'Speechlanguageimpairment',
  },
  {
    COMBOKEY: '210372001485',
    LEA_NAME: 'Madison County',
    LEA_STATE: 'KY',
    RR_AM_F_WH_F_SLI: 0,
    RR_AM_M_POP_SLI: 0,
    RR_AM_M_WH_M_SLI: 0,
    RR_AS_F_POP_SLI: 0,
    RR_AS_F_WH_F_SLI: 0,
    RR_AS_M_POP_SLI: 0,
    RR_AS_M_WH_M_SLI: 0,
    RR_BL_F_POP_SLI: 0,
    RR_BL_F_WH_F_SLI: 0,
    RR_BL_M_POP_SLI: 0,
    RR_BL_M_WH_M_SLI: 0,
    RR_HI_F_POP_SLI: 0,
    RR_HI_F_WH_F_SLI: 0,
    RR_HI_M_POP_SLI: 0,
    RR_HI_M_WH_M_SLI: 0,
    RR_HP_F_POP_SLI: null,
    RR_HP_F_WH_F_SLI: null,
    RR_HP_M_POP_SLI: null,
    RR_HP_M_WH_M_SLI: null,
    RR_TR_F_POP_SLI: 0,
    RR_TR_F_WH_F_SLI: 0,
    RR_TR_M_POP_SLI: 0,
    RR_TR_M_WH_M_SLI: 0,
    RR_WH_F_POP_SLI: 0.30141844,
    RR_WH_M_POP_SLI: 4.699300699,
    SCHOOL_NAME: 'Model Laboratory Elementary School',
    __typename: 'Speechlanguageimpairment',
  },
  {
    COMBOKEY: '210372001486',
    LEA_NAME: 'Madison County',
    LEA_STATE: 'KY',
    RR_AM_F_WH_F_SLI: null,
    RR_AM_M_POP_SLI: 0,
    RR_AM_M_WH_M_SLI: 0,
    RR_AS_F_POP_SLI: 0,
    RR_AS_F_WH_F_SLI: null,
    RR_AS_M_POP_SLI: 0,
    RR_AS_M_WH_M_SLI: 0,
    RR_BL_F_POP_SLI: 0,
    RR_BL_F_WH_F_SLI: null,
    RR_BL_M_POP_SLI: 0,
    RR_BL_M_WH_M_SLI: 0,
    RR_HI_F_POP_SLI: 0,
    RR_HI_F_WH_F_SLI: null,
    RR_HI_M_POP_SLI: null,
    RR_HI_M_WH_M_SLI: null,
    RR_HP_F_POP_SLI: null,
    RR_HP_F_WH_F_SLI: null,
    RR_HP_M_POP_SLI: null,
    RR_HP_M_WH_M_SLI: null,
    RR_TR_F_POP_SLI: 0,
    RR_TR_F_WH_F_SLI: null,
    RR_TR_M_POP_SLI: 0,
    RR_TR_M_WH_M_SLI: 0,
    RR_WH_F_POP_SLI: 0,
    RR_WH_M_POP_SLI: null,
    SCHOOL_NAME: 'Model Laboratory High School',
    __typename: 'Speechlanguageimpairment',
  },
  {
    COMBOKEY: '210372001536',
    LEA_NAME: 'Madison County',
    LEA_STATE: 'KY',
    RR_AM_F_WH_F_SLI: null,
    RR_AM_M_POP_SLI: null,
    RR_AM_M_WH_M_SLI: null,
    RR_AS_F_POP_SLI: 0,
    RR_AS_F_WH_F_SLI: null,
    RR_AS_M_POP_SLI: 0,
    RR_AS_M_WH_M_SLI: 0,
    RR_BL_F_POP_SLI: 0,
    RR_BL_F_WH_F_SLI: null,
    RR_BL_M_POP_SLI: null,
    RR_BL_M_WH_M_SLI: null,
    RR_HI_F_POP_SLI: 0,
    RR_HI_F_WH_F_SLI: null,
    RR_HI_M_POP_SLI: 0,
    RR_HI_M_WH_M_SLI: 0,
    RR_HP_F_POP_SLI: null,
    RR_HP_F_WH_F_SLI: null,
    RR_HP_M_POP_SLI: null,
    RR_HP_M_WH_M_SLI: null,
    RR_TR_F_POP_SLI: 0,
    RR_TR_F_WH_F_SLI: null,
    RR_TR_M_POP_SLI: 0,
    RR_TR_M_WH_M_SLI: 0,
    RR_WH_F_POP_SLI: 0,
    RR_WH_M_POP_SLI: null,
    SCHOOL_NAME: 'Model Laboratory Middle School',
    __typename: 'Speechlanguageimpairment',
  },
];

export default SCHOOLS_BY_NAME_SLI_RESPONSE;
