const SCHOOLS_BY_NAME_SLD_RESPONSE = [
  {
    COMBOKEY: '480783005840',
    LEA_NAME: 'ALIEF ISD',
    LEA_STATE: 'TX',
    RR_AM_F_WH_F_SLD: null,
    RR_AM_M_POP_SLD: 0,
    RR_AM_M_WH_M_SLD: null,
    RR_AS_F_POP_SLD: 0.325386997,
    RR_AS_F_WH_F_SLD: null,
    RR_AS_M_POP_SLD: 0,
    RR_AS_M_WH_M_SLD: null,
    RR_BL_F_POP_SLD: 1.892,
    RR_BL_F_WH_F_SLD: null,
    RR_BL_M_POP_SLD: 1.712121212,
    RR_BL_M_WH_M_SLD: null,
    RR_HI_F_POP_SLD: 0.125202375,
    RR_HI_F_WH_F_SLD: null,
    RR_HI_M_POP_SLD: 1.918722272,
    RR_HI_M_WH_M_SLD: null,
    RR_HP_F_POP_SLD: null,
    RR_HP_F_WH_F_SLD: null,
    RR_HP_M_POP_SLD: 0,
    RR_HP_M_WH_M_SLD: null,
    RR_TR_F_POP_SLD: 0,
    RR_TR_F_WH_F_SLD: null,
    RR_TR_M_POP_SLD: 0,
    RR_TR_M_WH_M_SLD: null,
    RR_WH_F_POP_SLD: 0,
    RR_WH_M_POP_SLD: 0,
    SCHOOL_NAME: 'ALBRIGHT MIDDLE',
    __typename: 'Specificlearningdisability',
  },
  {
    COMBOKEY: '173522003682',
    LEA_NAME: 'Salt Creek SD 48',
    LEA_STATE: 'IL',
    RR_AM_F_WH_F_SLD: null,
    RR_AM_M_POP_SLD: null,
    RR_AM_M_WH_M_SLD: null,
    RR_AS_F_POP_SLD: 0,
    RR_AS_F_WH_F_SLD: 0,
    RR_AS_M_POP_SLD: 0.725490196,
    RR_AS_M_WH_M_SLD: 0.688888889,
    RR_BL_F_POP_SLD: 1.901960784,
    RR_BL_F_WH_F_SLD: 3.055555556,
    RR_BL_M_POP_SLD: 1.621848739,
    RR_BL_M_WH_M_SLD: 1.476190476,
    RR_HI_F_POP_SLD: 0.529411765,
    RR_HI_F_WH_F_SLD: 0.916666667,
    RR_HI_M_POP_SLD: 3.111888112,
    RR_HI_M_WH_M_SLD: 2.348484848,
    RR_HP_F_POP_SLD: null,
    RR_HP_F_WH_F_SLD: null,
    RR_HP_M_POP_SLD: null,
    RR_HP_M_WH_M_SLD: null,
    RR_TR_F_POP_SLD: null,
    RR_TR_F_WH_F_SLD: null,
    RR_TR_M_POP_SLD: null,
    RR_TR_M_WH_M_SLD: null,
    RR_WH_F_POP_SLD: 0.527272727,
    RR_WH_M_POP_SLD: 1.112903226,
    SCHOOL_NAME: 'John E Albright Middle School',
    __typename: 'Specificlearningdisability',
  },
];

export default SCHOOLS_BY_NAME_SLD_RESPONSE;
