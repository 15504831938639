const SCHOOLS_BY_NAME_TOTAL_RESPONSE = [
  {
    COMBOKEY: '10000800851',
    LEA_NAME: 'Madison City',
    LEA_STATE: 'AL',
    RR_AM_F_POP: 1.630597015,
    RR_AM_F_WH_F: 1.986111109,
    RR_AM_M_POP: 0.643283582,
    RR_AM_M_WH_M: 0.620833331,
    RR_AS_F_POP: 0.860606061,
    RR_AS_F_WH_F: 1.059259258,
    RR_AS_M_POP: 1.519526627,
    RR_AS_M_WH_M: 1.432692303,
    RR_BL_F_POP: 1.035394933,
    RR_BL_F_WH_F: 1.263888888,
    RR_BL_M_POP: 1.411637931,
    RR_BL_M_WH_M: 1.293402774,
    RR_HI_F_POP: null,
    RR_HI_F_WH_F: null,
    RR_HI_M_POP: null,
    RR_HI_M_WH_M: null,
    RR_HP_F_POP: null,
    RR_HP_F_WH_F: null,
    RR_HP_M_POP: 6.567164179,
    RR_HP_M_WH_M: 6.208333314,
    RR_TR_F_POP: 0.71641791,
    RR_TR_F_WH_F: 0.882716049,
    RR_TR_M_POP: 0,
    RR_TR_M_WH_M: 0,
    RR_WH_F_POP: 0.75020979,
    RR_WH_F_WH_F: 1,
    RR_WH_M_POP: 1.068944478,
    RR_WH_M_WH_M: 1,
    SCH_NAME: 'West Madison Elementary School',
    __typename: 'School',
  },
  {
    COMBOKEY: '10033000090',
    LEA_NAME: 'Bessemer City',
    LEA_STATE: 'AL',
    RR_AM_F_POP: null,
    RR_AM_F_WH_F: null,
    RR_AM_M_POP: null,
    RR_AM_M_WH_M: null,
    RR_AS_F_POP: null,
    RR_AS_F_WH_F: null,
    RR_AS_M_POP: null,
    RR_AS_M_WH_M: null,
    RR_BL_F_POP: 0.376344086,
    RR_BL_F_WH_F: null,
    RR_BL_M_POP: 2.458333333,
    RR_BL_M_WH_M: null,
    RR_HI_F_POP: 2.52014652,
    RR_HI_F_WH_F: null,
    RR_HI_M_POP: 0,
    RR_HI_M_WH_M: null,
    RR_HP_F_POP: null,
    RR_HP_F_WH_F: null,
    RR_HP_M_POP: null,
    RR_HP_M_WH_M: null,
    RR_TR_F_POP: null,
    RR_TR_F_WH_F: null,
    RR_TR_M_POP: null,
    RR_TR_M_WH_M: null,
    RR_WH_F_POP: 0,
    RR_WH_F_WH_F: null,
    RR_WH_M_POP: 0,
    RR_WH_M_WH_M: null,
    SCH_NAME: 'Westhills Elementary School',
    __typename: 'School',
  },
  {
    COMBOKEY: '10036000098',
    LEA_NAME: 'Bibb County',
    LEA_STATE: 'AL',
    RR_AM_F_POP: null,
    RR_AM_F_WH_F: null,
    RR_AM_M_POP: null,
    RR_AM_M_WH_M: null,
    RR_AS_F_POP: null,
    RR_AS_F_WH_F: null,
    RR_AS_M_POP: null,
    RR_AS_M_WH_M: null,
    RR_BL_F_POP: 0,
    RR_BL_F_WH_F: 0,
    RR_BL_M_POP: 2.951612903,
    RR_BL_M_WH_M: 2.249999996,
    RR_HI_F_POP: 0,
    RR_HI_F_WH_F: 0,
    RR_HI_M_POP: 0,
    RR_HI_M_WH_M: 0,
    RR_HP_F_POP: null,
    RR_HP_F_WH_F: null,
    RR_HP_M_POP: null,
    RR_HP_M_WH_M: null,
    RR_TR_F_POP: null,
    RR_TR_F_WH_F: null,
    RR_TR_M_POP: null,
    RR_TR_M_WH_M: null,
    RR_WH_F_POP: 0.634285714,
    RR_WH_F_WH_F: 1,
    RR_WH_M_POP: 1.351851852,
    RR_WH_M_WH_M: 1,
    SCH_NAME: 'West Blocton Elementary School',
    __typename: 'School',
  },
  {
    COMBOKEY: '10036000099',
    LEA_NAME: 'Bibb County',
    LEA_STATE: 'AL',
    RR_AM_F_POP: 0,
    RR_AM_F_WH_F: 0,
    RR_AM_M_POP: 0,
    RR_AM_M_WH_M: 0,
    RR_AS_F_POP: null,
    RR_AS_F_WH_F: null,
    RR_AS_M_POP: 0,
    RR_AS_M_WH_M: 0,
    RR_BL_F_POP: 1.109243697,
    RR_BL_F_WH_F: 1.952941172,
    RR_BL_M_POP: 1.908284024,
    RR_BL_M_WH_M: 1.417378917,
    RR_HI_F_POP: null,
    RR_HI_F_WH_F: null,
    RR_HI_M_POP: null,
    RR_HI_M_WH_M: null,
    RR_HP_F_POP: null,
    RR_HP_F_WH_F: null,
    RR_HP_M_POP: 0,
    RR_HP_M_WH_M: 0,
    RR_TR_F_POP: null,
    RR_TR_F_WH_F: null,
    RR_TR_M_POP: 0,
    RR_TR_M_WH_M: 0,
    RR_WH_F_POP: 0.437632884,
    RR_WH_F_WH_F: 1,
    RR_WH_M_POP: 1.707951522,
    RR_WH_M_WH_M: 1,
    SCH_NAME: 'West Blocton High School',
    __typename: 'School',
  },
  {
    COMBOKEY: '10036001426',
    LEA_NAME: 'Bibb County',
    LEA_STATE: 'AL',
    RR_AM_F_POP: null,
    RR_AM_F_WH_F: null,
    RR_AM_M_POP: null,
    RR_AM_M_WH_M: null,
    RR_AS_F_POP: 6.409090909,
    RR_AS_F_WH_F: 10.60606058,
    RR_AS_M_POP: null,
    RR_AS_M_WH_M: null,
    RR_BL_F_POP: 2.44047619,
    RR_BL_F_WH_F: 3.977272718,
    RR_BL_M_POP: 4.599358974,
    RR_BL_M_WH_M: 3.897727271,
    RR_HI_F_POP: 0,
    RR_HI_F_WH_F: 0,
    RR_HI_M_POP: 0,
    RR_HI_M_WH_M: 0,
    RR_HP_F_POP: null,
    RR_HP_F_WH_F: null,
    RR_HP_M_POP: null,
    RR_HP_M_WH_M: null,
    RR_TR_F_POP: null,
    RR_TR_F_WH_F: null,
    RR_TR_M_POP: 0,
    RR_TR_M_WH_M: 0,
    RR_WH_F_POP: 0.45077551,
    RR_WH_F_WH_F: 1,
    RR_WH_M_POP: 1.075680272,
    RR_WH_M_WH_M: 1,
    SCH_NAME: 'West Blocton Middle School',
    __typename: 'School',
  },
  {
    COMBOKEY: '10039002187',
    LEA_NAME: 'Birmingham City',
    LEA_STATE: 'AL',
    RR_AM_F_POP: null,
    RR_AM_F_WH_F: null,
    RR_AM_M_POP: 0,
    RR_AM_M_WH_M: null,
    RR_AS_F_POP: null,
    RR_AS_F_WH_F: null,
    RR_AS_M_POP: null,
    RR_AS_M_WH_M: null,
    RR_BL_F_POP: 0.403263974,
    RR_BL_F_WH_F: null,
    RR_BL_M_POP: 2.696615385,
    RR_BL_M_WH_M: null,
    RR_HI_F_POP: 0,
    RR_HI_F_WH_F: null,
    RR_HI_M_POP: 0.931547619,
    RR_HI_M_WH_M: null,
    RR_HP_F_POP: null,
    RR_HP_F_WH_F: null,
    RR_HP_M_POP: null,
    RR_HP_M_WH_M: null,
    RR_TR_F_POP: null,
    RR_TR_F_WH_F: null,
    RR_TR_M_POP: null,
    RR_TR_M_WH_M: null,
    RR_WH_F_POP: 0,
    RR_WH_F_WH_F: null,
    RR_WH_M_POP: 0,
    RR_WH_M_WH_M: null,
    SCH_NAME: 'West End Academy',
    __typename: 'School',
  },
  {
    COMBOKEY: '10099000372',
    LEA_NAME: 'Cullman City',
    LEA_STATE: 'AL',
    RR_AM_F_POP: 0,
    RR_AM_F_WH_F: 0,
    RR_AM_M_POP: 0,
    RR_AM_M_WH_M: 0,
    RR_AS_F_POP: 0,
    RR_AS_F_WH_F: 0,
    RR_AS_M_POP: 0,
    RR_AS_M_WH_M: 0,
    RR_BL_F_POP: 3.573099415,
    RR_BL_F_WH_F: 7.083333263,
    RR_BL_M_POP: 0,
    RR_BL_M_WH_M: 0,
    RR_HI_F_POP: 0.255526083,
    RR_HI_F_WH_F: 0.544871789,
    RR_HI_M_POP: 2.742631579,
    RR_HI_M_WH_M: 5.032894687,
    RR_HP_F_POP: 0,
    RR_HP_F_WH_F: 0,
    RR_HP_M_POP: 0,
    RR_HP_M_WH_M: 0,
    RR_TR_F_POP: 1.046551724,
    RR_TR_F_WH_F: 2.124999979,
    RR_TR_M_POP: 0,
    RR_TR_M_WH_M: 0,
    RR_WH_F_POP: 0.362453066,
    RR_WH_F_WH_F: 1,
    RR_WH_M_POP: 1.96984127,
    RR_WH_M_WH_M: 1,
    SCH_NAME: 'West Elementary School',
    __typename: 'School',
  },
  {
    COMBOKEY: '10102000387',
    LEA_NAME: 'Cullman County',
    LEA_STATE: 'AL',
    RR_AM_F_POP: 0,
    RR_AM_F_WH_F: 0,
    RR_AM_M_POP: 0,
    RR_AM_M_WH_M: 0,
    RR_AS_F_POP: null,
    RR_AS_F_WH_F: null,
    RR_AS_M_POP: null,
    RR_AS_M_WH_M: null,
    RR_BL_F_POP: null,
    RR_BL_F_WH_F: null,
    RR_BL_M_POP: null,
    RR_BL_M_WH_M: null,
    RR_HI_F_POP: 0.342457421,
    RR_HI_F_WH_F: 0.344632768,
    RR_HI_M_POP: 0.482698962,
    RR_HI_M_WH_M: 0.486540378,
    RR_HP_F_POP: null,
    RR_HP_F_WH_F: null,
    RR_HP_M_POP: null,
    RR_HP_M_WH_M: null,
    RR_TR_F_POP: 0.635746606,
    RR_TR_F_WH_F: 0.63624511,
    RR_TR_M_POP: 1.042592593,
    RR_TR_M_WH_M: 0.957746478,
    RR_WH_F_POP: 1.01312513,
    RR_WH_F_WH_F: 1,
    RR_WH_M_POP: 1.180476295,
    RR_WH_M_WH_M: 1,
    SCH_NAME: 'West Point High School',
    __typename: 'School',
  },
  {
    COMBOKEY: '10102000458',
    LEA_NAME: 'Cullman County',
    LEA_STATE: 'AL',
    RR_AM_F_POP: null,
    RR_AM_F_WH_F: null,
    RR_AM_M_POP: 0,
    RR_AM_M_WH_M: 0,
    RR_AS_F_POP: 8.625,
    RR_AS_F_WH_F: 14.06666674,
    RR_AS_M_POP: null,
    RR_AS_M_WH_M: null,
    RR_BL_F_POP: null,
    RR_BL_F_WH_F: null,
    RR_BL_M_POP: null,
    RR_BL_M_WH_M: null,
    RR_HI_F_POP: 0.522321429,
    RR_HI_F_WH_F: 0.879166672,
    RR_HI_M_POP: 4.484993998,
    RR_HI_M_WH_M: 6.61960788,
    RR_HP_F_POP: null,
    RR_HP_F_WH_F: null,
    RR_HP_M_POP: null,
    RR_HP_M_WH_M: null,
    RR_TR_F_POP: 2.142857143,
    RR_TR_F_WH_F: 3.516666686,
    RR_TR_M_POP: 2.163636364,
    RR_TR_M_WH_M: 1.948275863,
    RR_WH_F_POP: 0.462085308,
    RR_WH_F_WH_F: 1,
    RR_WH_M_POP: 1.182364096,
    RR_WH_M_WH_M: 1,
    SCH_NAME: 'West Point Elementary School',
    __typename: 'School',
  },
  {
    COMBOKEY: '10102000459',
    LEA_NAME: 'Cullman County',
    LEA_STATE: 'AL',
    RR_AM_F_POP: null,
    RR_AM_F_WH_F: null,
    RR_AM_M_POP: 0,
    RR_AM_M_WH_M: 0,
    RR_AS_F_POP: 0,
    RR_AS_F_WH_F: 0,
    RR_AS_M_POP: 0,
    RR_AS_M_WH_M: 0,
    RR_BL_F_POP: 0,
    RR_BL_F_WH_F: 0,
    RR_BL_M_POP: 0,
    RR_BL_M_WH_M: 0,
    RR_HI_F_POP: 0.43438914,
    RR_HI_F_WH_F: 0.497058823,
    RR_HI_M_POP: 0.494871795,
    RR_HI_M_WH_M: 0.563333332,
    RR_HP_F_POP: null,
    RR_HP_F_WH_F: null,
    RR_HP_M_POP: null,
    RR_HP_M_WH_M: null,
    RR_TR_F_POP: 0.751923077,
    RR_TR_F_WH_F: 0.844999998,
    RR_TR_M_POP: 1.477532369,
    RR_TR_M_WH_M: 1.195804195,
    RR_WH_F_POP: 0.831988524,
    RR_WH_F_WH_F: 1,
    RR_WH_M_POP: 1.450521729,
    RR_WH_M_WH_M: 1,
    SCH_NAME: 'West Point Middle School',
    __typename: 'School',
  },
];

export default SCHOOLS_BY_NAME_TOTAL_RESPONSE;
