const selectOptions = {
  genderOptions: [
    { value: 'F', label: 'Female' },
    { value: 'M', label: 'Male' },
  ],
  raceOptions: [
    { value: 'AM', label: 'American Indian / Alaskan Native' },
    { value: 'AS', label: 'Asian' },
    { value: 'BL', label: 'Black' },
    { value: 'HI', label: 'Hispanic' },
    { value: 'HP', label: 'Pacific Islander' },
    { value: 'TR', label: 'Two or More Races' },
    { value: 'WH', label: 'White' },
  ],
  raceOptionsWhiteComparison: [
    { value: 'AM', label: 'American Indian / Alaskan Native' },
    { value: 'AS', label: 'Asian' },
    { value: 'BL', label: 'Black' },
    { value: 'HI', label: 'Hispanic' },
    { value: 'HP', label: 'Pacific Islander' },
    { value: 'TR', label: 'Two or More Races' },
  ],
  disabilityOptions: [
    { value: 'AUT', label: 'Autism' },
    { value: 'DB', label: 'Deaf-Blindness' },
    { value: 'DD', label: 'Developmental Delay' },
    { value: 'HI', label: 'Hearing Impairment' },
    { value: 'EMN', label: 'Emotional Disturbance' },
    { value: 'MD', label: 'Multiple Disabilities' },
    { value: 'MR', label: 'Intellectual Disability' },
    { value: 'OI', label: 'Orthopedic Impairment' },
    { value: 'OHI', label: 'Other Health Impairment' },
    { value: 'SLD', label: 'Specific Learning Disability' },
    { value: 'SLI', label: 'Speech or Language Impairment' },
    { value: 'TBI', label: 'Traumatic Brain Injury' },
    { value: 'VI', label: 'Visual Impairment' },
    { value: 'TOTAL', label: 'Total Special Education' },
  ],
  stateOptions: [
    { value: 'AL', label: 'ALABAMA' },
    { value: 'AK', label: 'ALASKA' },
    { value: 'AZ', label: 'ARIZONA' },
    { value: 'AR', label: 'ARKANSAS' },
    { value: 'CA', label: 'CALIFORNIA' },
    { value: 'CO', label: 'COLORADO' },
    { value: 'CT', label: 'CONNECTICUT' },
    { value: 'DE', label: 'DELAWARE' },
    { value: 'DC', label: 'DISTRICT OF COLUMBIA' },
    { value: 'FL', label: 'FLORIDA' },
    { value: 'GA', label: 'GEORGIA' },
    { value: 'HI', label: 'HAWAII' },
    { value: 'ID', label: 'IDAHO' },
    { value: 'IL', label: 'ILLINOIS' },
    { value: 'IN', label: 'INDIANA' },
    { value: 'IA', label: 'IOWA' },
    { value: 'KS', label: 'KANSAS' },
    { value: 'KY', label: 'KENTUCKY' },
    { value: 'LA', label: 'LOUISIANA' },
    { value: 'ME', label: 'MAINE' },
    { value: 'MD', label: 'MARYLAND' },
    { value: 'MA', label: 'MASSACHUSETTS' },
    { value: 'MI', label: 'MICHIGAN' },
    { value: 'MN', label: 'MINNESOTA' },
    { value: 'MS', label: 'MISSISSIPPI' },
    { value: 'MO', label: 'MISSOURI' },
    { value: 'MT', label: 'MONTANA' },
    { value: 'NE', label: 'NEBRASKA' },
    { value: 'NV', label: 'NEVADA' },
    { value: 'NH', label: 'NEW HAMPSHIRE' },
    { value: 'NJ', label: 'NEW JERSEY' },
    { value: 'NM', label: 'NEW MEXICO' },
    { value: 'NY', label: 'NEW YORK' },
    { value: 'NC', label: 'NORTH CAROLINA' },
    { value: 'ND', label: 'NORTH DAKOTA' },
    { value: 'OH', label: 'OHIO' },
    { value: 'OK', label: 'OKLAHOMA' },
    { value: 'OR', label: 'OREGON' },
    { value: 'PA', label: 'PENNSYLVANIA' },
    { value: 'RI', label: 'RHODE ISLAND' },
    { value: 'SC', label: 'SOUTH CAROLINA' },
    { value: 'SD', label: 'SOUTH DAKOTA' },
    { value: 'TN', label: 'TENNESSEE' },
    { value: 'TX', label: 'TEXAS' },
    { value: 'UT', label: 'UTAH' },
    { value: 'VT', label: 'VERMONT' },
    { value: 'VA', label: 'VIRGINIA' },
    { value: 'WA', label: 'WASHINGTON' },
    { value: 'WV', label: 'WEST VIRGINIA' },
    { value: 'WI', label: 'WISCONSIN' },
    { value: 'WY', label: 'WYOMING' },
  ],
};

export default selectOptions;
