const SCHOOLS_BY_NAME_OHI_RESPONSE = [
  {
    COMBOKEY: '300387000061',
    LEA_NAME: 'Billings Elem',
    LEA_STATE: 'MT',
    RR_AM_F_WH_F_OHI: null,
    RR_AM_M_POP_OHI: 0,
    RR_AM_M_WH_M_OHI: 0,
    RR_AS_F_POP_OHI: null,
    RR_AS_F_WH_F_OHI: null,
    RR_AS_M_POP_OHI: 0,
    RR_AS_M_WH_M_OHI: 0,
    RR_BL_F_POP_OHI: 0,
    RR_BL_F_WH_F_OHI: null,
    RR_BL_M_POP_OHI: 0,
    RR_BL_M_WH_M_OHI: 0,
    RR_HI_F_POP_OHI: 0,
    RR_HI_F_WH_F_OHI: null,
    RR_HI_M_POP_OHI: 0,
    RR_HI_M_WH_M_OHI: 0,
    RR_HP_F_POP_OHI: null,
    RR_HP_F_WH_F_OHI: null,
    RR_HP_M_POP_OHI: null,
    RR_HP_M_WH_M_OHI: null,
    RR_TR_F_POP_OHI: 0,
    RR_TR_F_WH_F_OHI: null,
    RR_TR_M_POP_OHI: 2.4,
    RR_TR_M_WH_M_OHI: 1.024,
    RR_WH_F_POP_OHI: 0,
    RR_WH_M_POP_OHI: 5.01953125,
    SCHOOL_NAME: 'Bench School',
    __typename: 'Otherhealthimpairment',
  },
  {
    COMBOKEY: '63432005570',
    LEA_NAME: 'San Diego Unified',
    LEA_STATE: 'CA',
    RR_AM_F_WH_F_OHI: null,
    RR_AM_M_POP_OHI: null,
    RR_AM_M_WH_M_OHI: null,
    RR_AS_F_POP_OHI: 0,
    RR_AS_F_WH_F_OHI: 0,
    RR_AS_M_POP_OHI: 0,
    RR_AS_M_WH_M_OHI: 0,
    RR_BL_F_POP_OHI: 0,
    RR_BL_F_WH_F_OHI: 0,
    RR_BL_M_POP_OHI: 3.296969697,
    RR_BL_M_WH_M_OHI: 1.8,
    RR_HI_F_POP_OHI: 0.742175857,
    RR_HI_F_WH_F_OHI: 0.846994536,
    RR_HI_M_POP_OHI: 0.703125,
    RR_HI_M_WH_M_OHI: 0.421875,
    RR_HP_F_POP_OHI: 0,
    RR_HP_F_WH_F_OHI: 0,
    RR_HP_M_POP_OHI: 0,
    RR_HP_M_WH_M_OHI: 0,
    RR_TR_F_POP_OHI: 0,
    RR_TR_F_WH_F_OHI: 0,
    RR_TR_M_POP_OHI: 0,
    RR_TR_M_WH_M_OHI: 0,
    RR_WH_F_POP_OHI: 0.868817204,
    RR_WH_M_POP_OHI: 2.450617284,
    SCHOOL_NAME: 'Benchley/Weinberger Elementary',
    __typename: 'Otherhealthimpairment',
  },
];

export default SCHOOLS_BY_NAME_OHI_RESPONSE;
